const mutations = {
    SET_USER(state, payload) {
        state.user = payload;
    },
    SET_ROLES(state, payload) {
        state.roles = payload;
        state.hasAdminRole = payload.some((item) => item.slug !== "basic");
    },
    SET_ACCESS_TOKEN(state, payload) {
        state.accessToken = payload;
    },
    SET_HAS_ADMIN_ROLE(state, payload = false) {
        state.hasAdminRole = payload;
    },
    SET_ACCESS_TOKEN_EXPIRATION_DATE(state, payload) {
        state.tokenExpirationDate = payload;
    },
    SET_MERCHANT_SCOPES(state, payload) {
        state.merchantScopes = payload;
    },
    SET_GLOBAL_SCOPES(state, payload) {
        state.globalScopes = payload;
    },
    SET_SCOPES(state, payload) {
        state.scopes = payload;
    },
    SET_ERROR_AUTH(state, payload) {
        state.error = payload;
    },
    CLEAR_AUTH(state) {
        state.user = {
            id: "",
            identificationType: "cc",
            identificationNumber: "",
            username: "",
            firstName: "",
            lastName: "",
            cityCode: "11001",
            address: "",
            neighborhood: "",
            email: "",
            phone: ""
        };
        state.accessToken = "";
        state.roles = [];
        state.scopes = [];
        state.globalScopes = [];
        state.merchantScopes = [];
        state.tokenExpirationDate = 0;
    }
};

export default mutations;
