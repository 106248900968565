const mutations = {
    SET_ORDERS(state, payload) {
        state.orders = [...payload];
    },
    SET_ERROR_ORDERS(state, payload) {
        state.error = payload;
    },
    SET_EDIT_ORDER(state, payload) {
        state.editSubline = payload;
    }
};

export default mutations;
