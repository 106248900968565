const mutations = {
    SET_ERROR_SUBLINES(state, payload) {
        state.error = payload;
    },
    SET_EDIT_SUBLINE(state, payload) {
        state.editSubline = payload;
    }
};

export default mutations;
