export const paymentStatusesCodes = {
    pnd: "Pago pendiente",
    rjt: "Pago rechazado",
    apd: "Pago aprobado",
    fld: "Pago fallido"
};

export const paymentStatuses = {
    pending: "pnd",
    rejected: "rjt",
    approved: "apd",
    failed: "fld"
};

export const paymentStatusesMessageClass = {
    pnd: "text-warning",
    apd: "text-success",
    rjt: "text-danger",
    fld: "text-danger"
};
