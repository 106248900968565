import { getDefaultUser } from "@/api/auth";
import { CognitoHostedUIIdentityProvider } from "@aws-amplify/auth/lib/types";
import { Auth } from "aws-amplify";
import { Base64 } from "js-base64";
import { DateTime } from "luxon";

import {
    ENABLED_ENCRYPT_SIGN_IN,
    ENABLED_GOOGLE_AUTH,
    OPERATION_MODE
} from "@/common/constants";
import { OperationsModes } from "@/common/fields/operations-modes";
import EncryptAdapter from "@/common/lib/encrypt";
import { handleErrorAxios, parseErrorAxios } from "@/common/lib/formatter";
import Http from "@/common/lib/http";

const _signInPasswordCognito = async ({ email, password }) => {
    try {
        await Auth.signIn(email, password);
        return { callback: "/callback" };
    } catch (error) {
        throw {
            message: error.message.includes("User does not exist")
                ? "Usuario no existe"
                : "Revisa los datos que ingresaste"
        };
    }
};

const _signInPasswordInternal = async ({
    commit,
    dispatch,
    email,
    password
}) => {
    try {
        const info = `Basic ${Base64.encode(email + ":" + password)}`;
        let response = {};
        if (ENABLED_ENCRYPT_SIGN_IN) {
            const encryptedData = EncryptAdapter.instance.parseString(info);
            response = await Http.instance.post("/bo/auth/security", {
                data: encryptedData
            });
        } else {
            response = await Http.instance.post(
                "/bo/auth/sign-in",
                {},
                {
                    headers: {
                        Authorization: info
                    }
                }
            );
        }
        const { data } = response;
        commit("SET_SCOPES", data.scopes);
        commit("SET_USER", {
            name: data.firstName,
            email: data.email,
            lastName: data.lastName,
            picture: "",
            sub: data.uid
        });
        commit("SET_ROLES", data.roles);
        commit("SET_ACCESS_TOKEN", data.accessToken);
        const globalScopes = data.scopes.map((item) => item.slug);
        dispatch("applyGlobalScopes", { globalScopes });
        return "/app";
    } catch (errorAxios) {
        throw parseErrorAxios(errorAxios);
    }
};

const _redirectApp = ({ email }) => {
    console.log("Email should redirect [%s]", email);
    location.href = "/app";
};

const _getSignInMethod = (operationMode) => {
    const fns = {
        [OperationsModes.posProtectedAccess]: _signInPasswordInternal,
        [OperationsModes.develop]: _signInPasswordInternal,
        [OperationsModes.marketplace]: _signInPasswordCognito,
        [OperationsModes.posSimpleAccess]: _redirectApp
    };

    return fns[operationMode];
};

const signInMethod = _getSignInMethod(OPERATION_MODE);

const actions = {
    async signInPassword({ commit, dispatch }, { email, password }) {
        try {
            const callback = await signInMethod({
                commit,
                dispatch,
                email,
                password
            });
            return callback;
        } catch (errorAxios) {
            commit("SET_ERROR_AUTH", errorAxios);
            throw {
                message: errorAxios.message,
                statusCode: 400,
                icon: "error",
                title: "Error 😥😣"
            };
        }
    },
    async signInGoogle({ commit, dispatch }) {
        try {
            await Auth.federatedSignIn({
                provider: CognitoHostedUIIdentityProvider.Google
            });
        } catch (errorAxios) {
            const parseError = handleErrorAxios(errorAxios, dispatch);
            commit("SET_ERROR_AUTH", parseError);
            throw parseError;
        }
    },
    async getAndApplyCurrentSession({ commit, dispatch }) {
        try {
            const res = await Auth.currentSession();
            const accessToken = res.getAccessToken();
            const idToken = res.getIdToken();
            commit("SET_ACCESS_TOKEN", accessToken.getJwtToken());
            commit(
                "SET_ACCESS_TOKEN_EXPIRATION_DATE",
                accessToken.getExpiration()
            );
            commit("SET_USER", {
                email: idToken.payload.email,
                lastName: idToken.payload.family_name,
                name: idToken.payload.name,
                picture: idToken.payload.picture,
                sub: idToken.payload.sub
            });
            const { data } = await Http.instance.get(
                "/bo/users/profile/status"
            );
            commit("SET_ROLES", data.roles);
            const globalScopes = data.scopes.map((item) => item.slug);
            dispatch("applyGlobalScopes", { globalScopes });
            return { needOnboarding: data.needOnboarding };
        } catch (errorAxios) {
            console.error({ errorAxios });
            const parseError = handleErrorAxios(errorAxios, dispatch);
            commit("SET_ERROR_AUTH", parseError);
            commit("CLEAR_AUTH");
            Auth.signOut();
            throw parseError;
        }
    },
    async getProfile({ commit, dispatch }) {
        try {
            const { data } = await Http.instance.get("/bo/users/profile", {
                params: {}
            });
            commit("SET_ROLES", data.roles);
            return data;
        } catch (errorAxios) {
            const parseError = handleErrorAxios(errorAxios, dispatch);
            commit("SET_ERROR_AUTH", parseError);
            throw parseError;
        }
    },
    async updateProfile(
        { commit, dispatch, state },
        {
            address,
            addressComplement,
            cityCode,
            firstName,
            identificationNumber,
            identificationType,
            lastName,
            neighborhood,
            phone
        }
    ) {
        try {
            const { data } = await Http.instance.patch("/bo/users/profile", {
                phone,
                address,
                cityCode,
                lastName,
                firstName,
                neighborhood,
                addressComplement,
                identificationType,
                identificationNumber
            });
            commit("SET_USER", {
                ...state.user,
                phone,
                address,
                cityCode,
                lastName,
                firstName,
                neighborhood,
                addressComplement,
                identificationType,
                identificationNumber
            });

            return data;
        } catch (errorAxios) {
            const parseError = handleErrorAxios(errorAxios, dispatch);
            commit("SET_ERROR_AUTH", parseError);
            throw parseError;
        }
    },
    async refreshAndValidateToken({ commit, dispatch, state }) {
        if (
            state.accessToken &&
            state.tokenExpirationDate <= DateTime.now().toUnixInteger()
        ) {
            console.log("Refresh token ...");
            try {
                const res = await Auth.currentSession();
                const refreshToken = res.getRefreshToken();
                const refreshedSession = await Auth.refreshSession(
                    refreshToken
                );
                const refreshedAccessToken = refreshedSession
                    .getAccessToken()
                    .getJwtToken();
                commit("SET_ACCESS_TOKEN", refreshedAccessToken);
                return refreshedAccessToken;
            } catch (error) {
                dispatch("signOut");
            }
            return state.accessToken;
        }
        return state.accessToken;
    },
    async applyDefaultUser({ commit, dispatch }) {
        if (OPERATION_MODE !== OperationsModes.posSimpleAccess) {
            console.log("Skip posSimpleAccess");
            return;
        }
        try {
            const { hasAdminRole, roles, scopes, user } =
                await getDefaultUser();
            commit("SET_ROLES", roles.slice(0, 1));
            commit("SET_HAS_ADMIN_ROLE", hasAdminRole);
            commit("SET_USER", {
                ...user,
                name: user.firstName,
                picture: "",
                sub: user.uid
            });
            const globalScopes = scopes.map((item) => item.slug);
            dispatch("applyGlobalScopes", {
                globalScopes
            });
        } catch (errorAxios) {
            const parseError = handleErrorAxios(errorAxios, dispatch);
            commit("SET_ERROR_AUTH", parseError);
            throw parseError;
        }
    },
    signOut({ commit }) {
        commit("CLEAR_AUTH");
        commit("commons/CLEAR_COMMONS", {}, { root: true });
        if (ENABLED_GOOGLE_AUTH) {
            Auth.signOut();
        }
        window.location.href = "/login";
    },
    applyGlobalScopes({ commit, state }, { globalScopes }) {
        commit("SET_GLOBAL_SCOPES", globalScopes);
        commit("SET_SCOPES", globalScopes);
        commit(
            "control/APPLY_SIDEBAR_ITEMS",
            {
                scopes: globalScopes,
                hasAdminRole: state.hasAdminRole
            },
            {
                root: true
            }
        );
        commit("control/APPLY_HOME_CARDS_LINKS", globalScopes, {
            root: true
        });
    },
    applyMerchantScopes({ commit, state }, merchantScopes = []) {
        commit("SET_MERCHANT_SCOPES", merchantScopes);
        const scopes = [...new Set([...state.globalScopes, ...merchantScopes])];
        commit("SET_SCOPES", scopes);
        commit(
            "control/APPLY_SIDEBAR_ITEMS",
            { scopes, hasAdminRole: state.hasAdminRole },
            {
                root: true
            }
        );
        commit("control/APPLY_HOME_CARDS_LINKS", scopes, {
            root: true
        });
    },
    reloadMerchantScopes({ commit, state }) {
        const globalScopes = state.globalScopes || [];
        const merchantScopes = state.merchantScopes || [];
        const scopes = [...new Set([...globalScopes, ...merchantScopes])];
        commit(
            "control/APPLY_SIDEBAR_ITEMS",
            { scopes, hasAdminRole: state.hasAdminRole },
            {
                root: true
            }
        );
        commit("control/APPLY_HOME_CARDS_LINKS", scopes, {
            root: true
        });
    }
};

export default actions;
