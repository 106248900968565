const state = {
    users: [],
    user: {},
    error: null,
    editUser: false,
    usersSearch: [],
    usersFilter: []
};

export default state;
