import { handleErrorAxios } from "@/common/lib/formatter";
import Http from "@/common/lib/http";

const actions = {
    async createLine(
        { commit, dispatch },
        { description, images, keywords, name, shortDescription, slug }
    ) {
        try {
            const { data } = await Http.instance.post("/bo/lines", {
                name,
                description,
                shortDescription,
                slug,
                keywords,
                images
            });
            return data;
        } catch (errorAxios) {
            const parseError = handleErrorAxios(errorAxios, dispatch);
            commit("SET_ERROR_LINES", parseError);
            throw parseError;
        }
    },

    async updateLineFilters({ commit, dispatch }, { filters, lineSlug }) {
        try {
            await Http.instance.patch("/bo/lines/filters", {
                lineSlug,
                filters
            });
        } catch (errorAxios) {
            const parseError = handleErrorAxios(errorAxios, dispatch);
            commit("SET_ERROR_LINES", parseError);
            throw parseError;
        }
    },

    async listLinesPaginated({ commit, dispatch }, { complete, page }) {
        try {
            const { data } = await Http.instance.get("/bo/lines", {
                params: { page, complete }
            });

            return data;
        } catch (errorAxios) {
            const parseError = handleErrorAxios(errorAxios, dispatch);
            commit("SET_ERROR_LINES", parseError);
            throw parseError;
        }
    },

    async listAllLines({ commit, dispatch }, { active, keyword }) {
        try {
            const { data } = await Http.instance.get("/bo/lines/all", {
                params: { active, keyword }
            });
            return data;
        } catch (errorAxios) {
            const parseError = handleErrorAxios(errorAxios, dispatch);
            commit("SET_ERROR_SUBLINES", parseError);
            throw parseError;
        }
    },

    async getLineBySlug({ commit, dispatch }, { slug }) {
        try {
            const { data } = await Http.instance.get(`/bo/lines/${slug}`);
            return data;
        } catch (errorAxios) {
            const parseError = handleErrorAxios(errorAxios, dispatch);
            commit("SET_ERROR_LINES", parseError);
            throw parseError;
        }
    },

    async updateLine(
        { commit, dispatch },
        {
            active,
            description,
            highlight,
            images,
            keywords,
            name,
            shortDescription,
            slug
        }
    ) {
        try {
            const { data } = await Http.instance.patch("/bo/lines", {
                name,
                description,
                highlight,
                shortDescription,
                keywords,
                slug,
                active,
                images
            });
            return data;
        } catch (errorAxios) {
            const parseError = handleErrorAxios(errorAxios, dispatch);
            commit("SET_ERROR_LINES", parseError);
            throw parseError;
        }
    },

    async deleteLine({ commit, dispatch }, { slug }) {
        try {
            await Http.instance.delete(`/bo/lines/${slug}`);
        } catch (errorAxios) {
            const parseError = handleErrorAxios(errorAxios, dispatch);
            commit("SET_ERROR_LINES", parseError);
            throw parseError;
        }
    }
};

export default actions;
