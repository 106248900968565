import { EMPTY_MONEY_CONFIG } from "@/common/lib/money";

const mutations = {
    SET_BRANDS_SHORTS(state, payload) {
        state.brandsShort = payload;
    },
    ADD_BRANDS_SHORTS(state, payload) {
        state.brandsShort.unshift(payload);
    },
    SET_LINES_SHORTS(state, payload) {
        state.linesActiveShort = payload;
    },
    ADD_LINES_SHORTS(state, payload) {
        state.linesActiveShort.unshift(payload);
    },
    SET_SUPPLIERS_ALL(state, payload) {
        state.suppliersAll = payload;
    },
    SET_SUBLINES_SHORT(state, payload) {
        state.linesAndSublinesAll = payload;
    },
    ADD_SUBLINES_SHORTS(state, payload) {
        state.linesAndSublinesAll.unshift(payload);
    },
    SET_MERCHANTS_SHORT(state, payload) {
        state.merchantsShort = payload;
    },
    ADD_MERCHANTS_SHORTS(state, payload) {
        state.merchantsShort.unshift(payload);
    },
    SET_ERROR_COMMONS(state, payload) {
        state.error = payload;
    },
    SET_MERCHANTS_OWN(state, payload) {
        state.merchantsOwn = payload;
    },
    SET_MERCHANT_SELECTED(state, payload) {
        state.selectedMerchant = payload;
    },
    UPDATE_MERCHANTS_GLOBAL(state, payload) {
        const merchants = [...state.merchantsOwn];
        const index = merchants.findIndex(
            (item) =>
                item.merchantId === payload.merchantId &&
                item.merchantType === payload.merchantType
        );
        if (index > -1) {
            merchants[index] = {
                ...merchants[index],
                ...payload
            };
            state.merchantsOwn = [...merchants];
        }
        if (
            state.selectedMerchant.merchantId === payload.merchantId &&
            state.selectedMerchant.merchantType === payload.merchantType
        ) {
            state.selectedMerchant = {
                ...state.selectedMerchant,
                ...payload
            };
        }
    },
    CLEAR_MERCHANTS(state) {
        state.merchantsOwn = [];
    },
    TOGGLE_IS_ENABLED_SERVICE_SEARCH(state) {
        state.isEnabledServiceSearch = !state.isEnabledServiceSearch;
    },
    CLEAR_COMMONS(state) {
        state.brandsShort = [];
        state.isEnabledServiceSearch = false;
        state.linesActiveShort = [];
        state.merchantsShort = [];
        state.suppliersAll = [];
        state.linesAndSublinesAll = [];
        state.error = null;
        state.merchantsOwn = [];
        state.selectedMerchant = {
            name: "",
            address: "",
            merchantType: "",
            merchantId: null,
            moneyConfig: EMPTY_MONEY_CONFIG
        };
    }
};

export default mutations;
