import { handleErrorAxios } from "@/common/lib/formatter";
import Http from "@/common/lib/http";

const actions = {
    async listNotifications({ commit, dispatch, state }, { required }) {
        if (!required && state.notifications.length) {
            return {
                notifications: state.notifications
            };
        }
        try {
            const { data } = await Http.instance.get("/bo/notifications");
            const notifications = data.items;
            commit("SET_NOTIFICATIONS", notifications);
            return { notifications };
        } catch (errorAxios) {
            const parseError = handleErrorAxios(errorAxios, dispatch);
            commit("SET_ERROR_NOTIFICATIONS", parseError);
            throw parseError;
        }
    },

    async verifyNotification({ commit, dispatch }, { id }) {
        try {
            const { data } = await Http.instance.post(
                `/bo/notifications/verify/${id}`
            );
            commit("UPDATE_NOTIFICATION", data);
        } catch (errorAxios) {
            const parseError = handleErrorAxios(errorAxios, dispatch);
            commit("SET_ERROR_NOTIFICATIONS", parseError);
            throw parseError;
        }
    }
};

export default actions;
