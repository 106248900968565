const getters = {
    borderCardDark(state) {
        return state.activeDark ? "border-warning" : "border-success";
    },
    tableDark(state) {
        return state.activeDark ? "table-dark" : "border border-black";
    },
    stateInputDark(state) {
        return state.activeDark ? "success" : "primary";
    },
    backgroundColorTextArea(state) {
        return state.activeDark ? "bg-input-success" : "bg-input-primary";
    },
    backgroundColor(state) {
        return state.activeDark ? "#0a1f3b" : "#f3f5f4";
    }
};

export default getters;
