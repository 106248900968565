import { handleErrorAxios } from "@/common/lib/formatter";
import Http from "@/common/lib/http";

const actions = {
    async listStoreOutputs(
        { commit, dispatch },
        { merchantId, merchantType, query }
    ) {
        try {
            const { data } = await Http.instance.get("/bo/outputs", {
                params: {
                    merchantId,
                    merchantType,
                    ...query
                }
            });

            return data.items;
        } catch (errorAxios) {
            const parseError = handleErrorAxios(errorAxios, dispatch);
            commit("SET_ERROR_OUTPUTS", parseError);
            throw parseError;
        }
    },
    async createStoreOutputs(
        { commit, dispatch },
        { concept, merchantId, merchantType, moneyConfig }
    ) {
        try {
            const { data } = await Http.instance.post("/bo/outputs", {
                merchantId,
                merchantType,
                concept,
                moneyConfig
            });
            return data;
        } catch (errorAxios) {
            const parseError = handleErrorAxios(errorAxios, dispatch);
            commit("SET_ERROR_OUTPUTS", parseError);
            throw parseError;
        }
    }
};

export default actions;
