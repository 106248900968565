<template>
    <div
        class="shadow border-radius-sw border"
        :class="activeDarkMode ? 'border-success' : 'border-warning'"
    >
        <vs-switch
            v-model="activeDarkMode"
            aria-label="Change theme"
            @input="onInput"
        >
            <template #circle>
                {{ activeDarkMode ? "🌛" : "🌞" }}
            </template>
        </vs-switch>
    </div>
</template>

<script>
import { mapMutations, mapState } from "vuex";

export default {
    data: () => ({
        activeDarkMode: false
    }),
    computed: {
        ...mapState("control", ["activeDark"])
    },
    beforeMount() {
        this.setInput(this.activeDark);
    },
    methods: {
        ...mapMutations("control", ["UPDATED_ACTIVE_DARK_MODE"]),
        changeColorMode() {
            const darkMode = document
                .querySelector("#father")
                .classList.toggle("dark-mode");
            this.$vs.setColor("background", darkMode ? "#01071d" : "#f3f5f4");
            this.$vs.setColor("primary", darkMode ? "#02913f" : "#253C99");
            this.$vs.setColor("text", darkMode ? "#f3f5f4" : "#2C3E50");
        },
        onInput() {
            this.changeColorMode();
            this.UPDATED_ACTIVE_DARK_MODE(this.activeDarkMode);
        },
        setInput(value) {
            this.activeDarkMode = !!value;
            if (
                document
                    .querySelector("#father")
                    .classList.contains("dark-mode") !== this.activeDarkMode
            ) {
                this.changeColorMode();
            }
        }
    }
};
</script>

<style scoped>
.border-radius-sw {
    border-radius: 20px;
}
</style>
