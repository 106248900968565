/* eslint-disable no-console */
import { Workbox } from "workbox-window";

let wb;
if (process.env.NODE_ENV === "production" && "serviceWorker" in navigator) {
    wb = new Workbox(`${process.env.VUE_APP_SITE_BASE_PATH}service-worker.js`);

    wb.addEventListener("controlling", () => {
        window.location.reload();
    });

    wb.register();
} else {
    wb = null;
}

export default wb;
