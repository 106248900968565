import { ENABLED_ECOMMERCE_OPTIONS, SITE_TYPE } from "@/common/constants";

export const homeCardsItems = [
    {
        name: "Registrar venta",
        id: "sales_new_in_merchant",
        icon: "💰",
        description:
            "Registra la venta de productos y/o servicios en tu comercio. Asocia la venta a cliente y decrementar el inventario.",
        isDefault: true,
        scope: "sale:Create",
        url: "/app/orders/new-in-merchant"
    },
    {
        name: "Venta Rápida",
        id: "sale_record",
        icon: "💰",
        description:
            "Crea una venta rápida. Crea una venta sin la necesidad asociar inventario. Te permitirá registrar el dinero ingresado en la caja.",
        isDefault: true,
        scope: "sale:Create",
        url: "/app/orders/record"
    },
    {
        name: "Ordenes",
        id: "orders",
        icon: "💳",
        description: "Consulta las ordenes por diferentes tipos de filtros.",
        isDefault: false,
        scope: "order:List",
        url: "/app/orders"
    },
    {
        name: "Historial de ventas",
        id: "orders",
        icon: "💳",
        description:
            "Consulta tus ordenes y ventas filtrado por fecha y otros tipos de filtros.",
        isDefault: true,
        scope: "order:ListOwn",
        url: "/app/orders/own"
    },
    {
        name: "Grupos de ordenes",
        id: "orders-groups",
        icon: "💳",
        description:
            "Consulta todas las ordenes padres. Estas los productos de estas ordenes son abastecidos por diferentes tiendas o bodegas.",
        isDefault: false,
        scope: "orderGroup:List",
        url: "/app/orders-groups"
    },
    {
        name: "Referencias pendientes",
        id: "orders_references",
        icon: "💳",
        description:
            "Consulta los productos y referencias que estan pendientes por entregar",
        isDefault: false,
        scope: "order:ListReferences",
        url: "/app/orders/references"
    },
    {
        name: `Registrar venta ${SITE_TYPE}`,
        id: "sales_new",
        icon: "💰",
        description:
            "Registra una  venta. Puedes seleccionar el método de pago y el cliente.",
        isDefault: false,
        scope: "sale:CreateWithGlobalPrices",
        url: "/app/orders/new"
    },
    {
        name: "Ventas por producto",
        id: "sales_product",
        icon: "💰",
        description:
            "Consulta las ventas de un producto a lo largo del tiempo.",
        isDefault: false,
        scope: "sale:ListByProduct",
        url: "/app/orders/product"
    },
    {
        name: "Inventario",
        id: "inventories_list",
        icon: "🧮",
        description:
            "Consulta los productos registrados en el inventario de tu tienda. Puedes filtrar los productos por marca, línea o palabra clave.",
        isDefault: true,
        scope: "inventory:Get",
        url: "/app/inventories"
    },
    {
        name: "Carga masiva de inventario",
        id: "inventories_massive",
        icon: "🧮",
        description:
            "Actualiza o ingresa productos a tu inventario. con un archivo podrás actualizar el inventario de tu comercio.",
        isDefault: true,
        scope: "inventory:Create",
        url: "/app/inventories/massive"
    },
    {
        name: "Registrar inventario",
        id: "inventories_new",
        icon: "🧮",
        description:
            "Actualiza tu inventario de productos. Puedes llevar un registro de los productos que has surtido en tu comercio.",
        isDefault: false,
        scope: "inventory:Create",
        url: "/app/inventories/new"
    },
    {
        name: "Nuevo carrito",
        id: "cart_new",
        icon: "🛒",
        description:
            "Crea un nuevo carro de compras para compartir con los clientes.",
        isDefault: false,
        scope: "cart:List",
        url: "/app/carts/new"
    },
    {
        name: "Lista carros de compras",
        id: "carts",
        scope: "carts:List",
        icon: "🛒",
        description:
            "Lista los carros de compras creados para compartir con los clientes.",
        isDefault: false,
        url: "/app/carts"
    },
    {
        name: "Productos",
        scope: "product:List",
        id: "products",
        icon: "🛍️",
        description:
            "Consulta los productos registrados. Puedes filtrar por marca de productos, línea del producto o palabra clave.",
        isDefault: true,
        url: "/app/products"
    },
    {
        name: "Carga masiva de productos",
        id: "products",
        scope: "product:LoadMassive",
        icon: "🛍️",
        description:
            "Facilita la creación y actualización de productos mediante la utilización de un archivo de Excel.",
        isDefault: true,
        url: "/app/products/load"
    },
    {
        name: "Busca productos",
        scope: "product:Search",
        id: "products",
        icon: "🛍️",
        description:
            "Busca los productos registrados de forma avanzada. Búsqueda por código de barras.",
        isDefault: false,
        url: "/app/products/search"
    },
    {
        name: "Crear producto",
        id: "products",
        scope: "product:Create",
        icon: "🛍️",
        description:
            "Crea un nuevo producto con todos sus campos y configuraciones.",
        isDefault: false,
        url: "/app/products/new"
    },
    {
        name: "Registrar producto",
        id: "products",
        scope: "product:Record",
        icon: "🛍️",
        description: `Registro de productos: crea y añade nuevos productos al ${SITE_TYPE}.`,
        isDefault: false,
        url: "/app/products/record"
    },
    {
        name: "Referencias destacados",
        id: "products",
        scope: "referencias:ListHighlight",
        icon: "🛍️",
        description:
            "Registra referencias de productos destacadas por línea y para la pantalla principal.",
        isDefault: ENABLED_ECOMMERCE_OPTIONS,
        url: "/app/products/highlight"
    },
    {
        name: "Lista de tiendas",
        id: "stores_list",
        icon: "🏪",
        description:
            "Consulta tus tiendas registradas o las que tengas acceso.",
        isDefault: false,
        scope: "store:List",
        url: "/app/stores"
    },
    {
        name: "Registrar tienda",
        id: "stores_record",
        icon: "🏪",
        description:
            "Registra una nueva tienda donde podrás gestionar tanto el inventario como realizar ventas de manera eficiente.",
        isDefault: false,
        scope: "store:CreateOwn",
        url: "/app/stores/record"
    },
    {
        name: "Crear tienda",
        id: "stores_new",
        icon: "🏪",
        description:
            "Crea nuevas tiendas para incrementar la cobertura de nuestra red de tiendas.",
        isDefault: false,
        scope: "store:Create",
        url: "/app/stores/new"
    },
    {
        name: "Clientes",
        id: "customers",
        icon: "👩",
        description: `Consulta y busca los clientes registrados en el ${SITE_TYPE}. Identifica las ventas registradas para un cliente.`,
        isDefault: !ENABLED_ECOMMERCE_OPTIONS,
        scope: "customer:List",
        url: "/app/customers"
    },
    {
        name: "Registrar cliente",
        id: "customers",
        icon: "👩",
        description:
            "Registra tus clientes. Una vez creado el cliente puedes asignar ventas. Si el cliente regresa nuevamente podrás saber que productos compró.",
        isDefault: !ENABLED_ECOMMERCE_OPTIONS,
        scope: "customer:Create",
        url: "/app/customers/new"
    },
    {
        name: "Caja",
        id: "merchant_box",
        icon: "👩‍🏭",
        description:
            "Consulta el dinero en efectivo y otras plataformas electrónicas igresado por ventas.",
        isDefault: !ENABLED_ECOMMERCE_OPTIONS,
        scope: "merchant:GetBox",
        url: "/app/merchant/box"
    },
    {
        name: "Servicios",
        id: "merchant_services",
        icon: "👩‍🏭",
        description:
            "Podrás registrar los servicios adicionales que ofreces en tu comercio. Luego para crear ventas con los servicios registrados.",
        isDefault: !ENABLED_ECOMMERCE_OPTIONS,
        scope: "merchant:GetServices",
        url: "/app/merchant/services"
    },
    {
        name: "Marcas registradas",
        id: "brands",
        icon: "🔖",
        description:
            "Consulta todas las marcas registradas en el sistema. Puedes usar las marcas registradas para asociar productos.",
        isDefault: false,
        scope: "",
        url: "/app/brands"
    },
    {
        name: "Registra marca",
        id: "brands",
        icon: "🔖",
        description: "Registra marcas nuevas para asociar a los productos.",
        isDefault: false,
        scope: "brand:Create",
        url: "/app/brands/new"
    },
    {
        name: "Marcas destacadas",
        id: "brands",
        icon: "🔖",
        description:
            "Registra marcas destacadas para las líneas y la pantalla principal.",
        isDefault: false,
        scope: "brand:ListHighlight",
        url: "/app/brands/highlight"
    },
    {
        name: "Categorías registradas",
        id: "categories",
        icon: "🏷",
        description: "Consulta todas las categorías registradas",
        isDefault: false,
        scope: "category:List",
        url: "/app/categories"
    },
    {
        name: "Registrar categoría",
        id: "categories",
        icon: "🏷",
        description:
            "Registra una nueva categorías, para posteriormente asociar productos.",
        isDefault: false,
        scope: "category:Create",
        url: "/app/categories/new"
    },
    {
        name: "Categorías destacadas",
        id: "categories",
        icon: "🏷",
        description: "Lista de categorías destacados",
        isDefault: false,
        scope: "ListHighlight",
        url: "/app/categories/highlight"
    },
    {
        name: "Lista de líneas",
        id: "lines",
        icon: "➖",
        description:
            "Consulta las líneas principales disponibles para agrupar productos.",
        isDefault: false,
        scope: "",
        url: "/app/lines"
    },
    {
        name: "Registrar línea",
        id: "lines",
        icon: "➖",
        description:
            "Crea nueva lines para agrupar productos y sublíneas, de esta manera mantienes tu catálogo ordenado.",
        isDefault: false,
        scope: "line:Create",
        url: "/app/lines/new"
    },
    {
        name: "Lista de sublíneas",
        id: "lines",
        icon: "➖",
        description:
            "Consulta las sublíneas disponibles para agrupar productos",
        isDefault: false,
        scope: "",
        url: "/app/sublines"
    },
    {
        name: "Registrar sublínea",
        id: "lines",
        icon: "➖",
        description:
            "Crea Registrar sublíneas para agrupar productos y tener tu catálogo ordenado.",
        isDefault: false,
        scope: "subline:Create",
        url: "/app/sublines/new"
    },
    {
        name: "Registra un gasto",
        id: "cash_outputs",
        icon: "🏪",
        description:
            "Registrar loas gastos para llevar control de tus finanzas (pagos de facturas, compra de insumos, etc ).",
        isDefault: false,
        scope: "cashOutput:Create",
        url: "/app/cash-outputs/new"
    },
    {
        name: "Gastos registrados",
        id: "cash_outputs",
        icon: "🏪",
        description:
            "Listado de gastos de comercio registrados (Pago de servicios, Compra de insumos, pago de dividendos)",
        isDefault: true,
        scope: "cashOutput:List",
        url: "/app/cash-outputs"
    },
    {
        name: "Lista de tiendas",
        id: "stores_list",
        icon: "🏪",
        description:
            "Consulta tus tiendas registradas o las que tengas acceso.",
        isDefault: false,
        scope: "store:List",
        url: "/app/stores"
    },
    {
        name: "Nuevo proveedor",
        id: "supplier_new",
        icon: "🏭",
        description: "",
        isDefault: false,
        scope: "supplier:Create",
        url: "/app/suppliers/new"
    },
    {
        name: "Lista de proveedores",
        id: "suppliers_list",
        icon: "🏭",
        description: "",
        isDefault: false,
        scope: "supplier:List",
        url: "/app/suppliers"
    },
    {
        name: "Registrar bodega",
        id: "warehouses_new",
        icon: "🏬",
        description:
            "Crea nuevas bodegas para incrementar la cobertura de nuestra red de bodegas.",
        isDefault: false,
        scope: "warehouse:Create",
        url: "/app/warehouses/new"
    },
    {
        name: "Lista de bodegas",
        id: "warehouses_list",
        icon: "🏬",
        description:
            "Consulta tus bodegas registradas o las que tengas acceso.",
        isDefault: false,
        scope: "warehouse:List",
        url: "/app/warehouses"
    },
    {
        name: "Histórico de inventario",
        id: "inventories_list",
        icon: "🧮",
        description:
            "Consulta el historico de registro de productos del inventario. Puedes filtrar por fecha para obtener detalle de cuando has surtido tu tienda.",
        isDefault: false,
        scope: "inventory:ListDate",
        url: "/app/inventories/history"
    },
    {
        name: "Usuarios",
        id: "users",
        icon: "👩‍💻",
        description: "Consulta lo usuarios registrados.",
        isDefault: false,
        scope: "user:List",
        url: "/app/users"
    },
    {
        name: "Colaboradores",
        id: "users",
        icon: "👩‍🔧",
        description:
            "Consulta los colaboradores que tienen acceso a tu alamacén e invitar a más personas para que hagan operaciones en el.",
        isDefault: true,
        scope: "user:ListOwn",
        url: "/app/employees"
    },
    {
        name: "Roles de usuario",
        id: "roles",
        icon: "👤",
        description:
            "Lista los roles registrados y sincroniza la información con el servidor.",
        isDefault: false,
        scope: "role:List",
        url: "/app/roles"
    },
    {
        name: "Consulta imágenes registradas",
        id: "images",
        icon: "📷",
        description:
            "Consulta las imágenes que tiendes disponibles para asociar a productos, líneas, categorías, tiendas entre otros.",
        isDefault: false,
        scope: "image:List",
        url: "/app/images"
    },
    {
        name: "Cargar imagen",
        id: "images",
        icon: "📷",
        description:
            "Puedes subir imágenes y posteriormente asociarlas a productos, categorías, marcas, tiendas, entre otros.",
        isDefault: false,
        scope: "image:Create",
        url: "/app/images/new"
    },
    {
        name: "Cargar archivo",
        id: "files",
        icon: "🗂",
        description:
            "Puedes subir archivos y posteriormente asociarlos a productos y blogs.",
        isDefault: false,
        scope: "file:Create",
        url: "/app/files/new"
    },
    {
        name: "Archivos cargados",
        id: "files",
        icon: "🗂",
        description:
            "Consulta los archivos  disponibles para asociar a productos o blogs.",
        isDefault: false,
        scope: "file:List",
        url: "/app/files"
    },
    {
        name: "Listas de compras",
        id: "supply_list_new",
        icon: "🧮",
        description:
            "Consulta las listas de compras para pendientes para abastecer tu inventario.",
        isDefault: !ENABLED_ECOMMERCE_OPTIONS,
        scope: "supplyList:List",
        url: "/app/supply-lists"
    },
    {
        name: "Registrar lista de compras",
        id: "supply_list_list",
        icon: "🧮",
        description:
            "Puedes registrar los productos que debes de comprar para mantener tu inventario al día.",
        isDefault: false,
        scope: "supplyList:Create",
        url: "/app/supply-lists/new"
    }
];
