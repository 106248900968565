import { handleErrorAxios } from "@/common/lib/formatter";
import Http from "@/common/lib/http";
import { formatReference } from "@/common/lib/reference";

const actions = {
    async verifyMassiveProductsAndInventories(
        { commit, dispatch },
        { merchantId, merchantType, rows }
    ) {
        try {
            const { data } = await Http.instance.post(
                "/bo/products/massive/verify-with-inventories",
                {
                    rows,
                    merchantId,
                    merchantType
                }
            );
            return data;
        } catch (errorAxios) {
            const parseError = handleErrorAxios(errorAxios, dispatch);
            commit("SET_ERROR_PRODUCTS", parseError);
            throw parseError;
        }
    },
    async saveMassiveProductsAndInventories(
        { commit, dispatch },
        { merchantId, merchantType, productsAndInventories }
    ) {
        try {
            const { data } = await Http.instance.post(
                "/bo/products/massive/save-with-inventories",
                {
                    merchantId,
                    merchantType,
                    productsAndInventories
                }
            );
            return data;
        } catch (errorAxios) {
            const parseError = handleErrorAxios(errorAxios, dispatch);
            commit("SET_ERROR_PRODUCTS", parseError);
            throw parseError;
        }
    },
    async verifyMassiveProducts({ commit, dispatch }, { rows }) {
        try {
            const { data } = await Http.instance.post(
                "/bo/products/massive/verify",
                {
                    rows
                }
            );
            return data;
        } catch (errorAxios) {
            const parseError = handleErrorAxios(errorAxios, dispatch);
            commit("SET_ERROR_PRODUCTS", parseError);
            throw parseError;
        }
    },
    async saveMassiveProducts({ commit, dispatch }, { products }) {
        try {
            const { data } = await Http.instance.post(
                "/bo/products/massive/save",
                {
                    products
                }
            );
            return data;
        } catch (errorAxios) {
            const parseError = handleErrorAxios(errorAxios, dispatch);
            commit("SET_ERROR_PRODUCTS", parseError);
            throw parseError;
        }
    },
    async createProduct(
        { commit, dispatch },
        {
            brand,
            deliveryOptions,
            description,
            features,
            genders,
            keywords,
            madeIn,
            name,
            shortDescription,
            sku,
            slug,
            tags,
            type
        }
    ) {
        try {
            const { data } = await Http.instance.post("/bo/products", {
                name,
                sku,
                type,
                slug,
                tags,
                madeIn,
                genders,
                keywords,
                features,
                description,
                deliveryOptions,
                shortDescription,
                brandSlug: brand.slug
            });
            return data;
        } catch (errorAxios) {
            const parseError = handleErrorAxios(errorAxios, dispatch);
            commit("SET_ERROR_PRODUCTS", parseError);
            throw parseError;
        }
    },
    async createProductFast(
        { commit, dispatch },
        {
            barcode,
            brandSlug,
            color,
            colorRef,
            cost,
            images,
            lineSlug,
            merchantId,
            merchantType,
            name,
            price,
            size,
            stock
        }
    ) {
        try {
            const { data } = await Http.instance.post("/bo/products/record", {
                name,
                size,
                cost,
                price,
                color,
                stock,
                images,
                lineSlug,
                brandSlug,
                merchantId,
                merchantType,
                barcode,
                colorRef
            });
            return data;
        } catch (errorAxios) {
            const parseError = handleErrorAxios(errorAxios, dispatch);
            commit("SET_ERROR_PRODUCTS", parseError);
            throw parseError;
        }
    },
    async listProductsPaginated(
        { commit, dispatch },
        { complete, page, query }
    ) {
        try {
            const { data } = await Http.instance.get("/bo/products", {
                params: { ...query, page, complete }
            });
            return data;
        } catch (errorAxios) {
            const parseError = handleErrorAxios(errorAxios, dispatch);
            commit("SET_ERROR_PRODUCTS", parseError);
            throw parseError;
        }
    },
    async listAllProducts(
        { commit, dispatch },
        { active, brandSlug, keyword, lineSlug }
    ) {
        try {
            const { data } = await Http.instance.get("/bo/products/all", {
                params: { active, lineSlug, brandSlug, keyword }
            });

            return data.items;
        } catch (errorAxios) {
            const parseError = handleErrorAxios(errorAxios, dispatch);
            commit("SET_ERROR_PRODUCTS", parseError);
            throw parseError;
        }
    },
    async getProductById({ commit, dispatch }, { id }) {
        try {
            const { data } = await Http.instance.get(`/bo/products/${id}`);
            return data;
        } catch (errorAxios) {
            const parseError = handleErrorAxios(errorAxios, dispatch);
            commit("SET_ERROR_PRODUCTS", parseError);
            throw parseError;
        }
    },
    async getProductByBarcode({ commit, dispatch }, { barcode }) {
        try {
            const { data } = await Http.instance.get(
                `/bo/products/barcode/${barcode}`
            );
            return data;
        } catch (errorAxios) {
            const parseError = handleErrorAxios(errorAxios, dispatch);
            commit("SET_ERROR_PRODUCTS", parseError);
            throw parseError;
        }
    },
    async updateProduct(
        { commit, dispatch },
        {
            brand,
            description,
            features,
            genders,
            id,
            keywords,
            madeIn,
            name,
            shortDescription,
            sku,
            slug,
            tags,
            taxRate,
            type,
            weight
        }
    ) {
        try {
            const { data } = await Http.instance.patch("/bo/products", {
                brandSlug: brand.slug,
                description,
                features,
                genders,
                id,
                keywords,
                madeIn,
                name,
                shortDescription,
                sku,
                slug,
                tags,
                taxRate,
                type,
                weight
            });

            return data;
        } catch (errorAxios) {
            const parseError = handleErrorAxios(errorAxios, dispatch);
            commit("SET_ERROR_PRODUCTS", parseError);
            throw parseError;
        }
    },
    async updateProductDelivery(
        { commit, dispatch },
        { id, logisticConfigurationSlug }
    ) {
        try {
            const { data } = await Http.instance.patch("/bo/products", {
                id,
                logisticConfigurationSlug
            });
            return data;
        } catch (errorAxios) {
            const parseError = handleErrorAxios(errorAxios, dispatch);
            commit("SET_ERROR_PRODUCTS", parseError);
            throw parseError;
        }
    },
    async updateProductsCategories(
        { commit, dispatch },
        { categoriesSlugs, productId }
    ) {
        try {
            await Http.instance.patch("/bo/products/categories", {
                productId,
                categoriesSlugs
            });
        } catch (errorAxios) {
            const parseError = handleErrorAxios(errorAxios, dispatch);
            commit("SET_ERROR_PRODUCTS", parseError);
            throw parseError;
        }
    },
    async updateProductsFiles({ commit, dispatch }, { filesIds, productId }) {
        try {
            await Http.instance.patch("/bo/products/files", {
                productId,
                filesIds
            });
        } catch (errorAxios) {
            const parseError = handleErrorAxios(errorAxios, dispatch);
            commit("SET_ERROR_PRODUCTS", parseError);
            throw parseError;
        }
    },
    async updateProductLine({ commit, dispatch }, { lineSlug, productId }) {
        try {
            await Http.instance.patch("/bo/products/line", {
                productId,
                lineSlug
            });
        } catch (errorAxios) {
            const parseError = handleErrorAxios(errorAxios, dispatch);
            commit("SET_ERROR_PRODUCTS", parseError);
            throw parseError;
        }
    },
    async publishProduct({ commit, dispatch }, { active, id, weight }) {
        try {
            const { data } = await Http.instance.put("/bo/products/publish", {
                id,
                active,
                weight
            });
            return data;
        } catch (errorAxios) {
            const parseError = handleErrorAxios(errorAxios, dispatch);
            commit("SET_ERROR_PRODUCTS", parseError);
            throw parseError;
        }
    },
    async publishMassiveProducts({ commit, dispatch }, { products }) {
        try {
            const { data } = await Http.instance.post(
                "/bo/products/publish/massive",
                {
                    products
                }
            );
            return data;
        } catch (errorAxios) {
            const parseError = handleErrorAxios(errorAxios, dispatch);
            commit("SET_ERROR_PRODUCTS", parseError);
            throw parseError;
        }
    },
    async deleteProduct({ commit, dispatch }, { definitely = false, id }) {
        try {
            await Http.instance.delete(
                `/bo/products/${id}?definitely=${definitely}`
            );
        } catch (errorAxios) {
            const parseError = handleErrorAxios(errorAxios, dispatch);
            commit("SET_ERROR_PRODUCTS", parseError);
            throw parseError;
        }
    },
    async createReference(
        { commit, dispatch },
        {
            alwaysStock,
            barcode,
            color,
            colorRef,
            images,
            minUnitsForPriceMerchants,
            price,
            priceMerchant,
            priceOffer,
            productId,
            size,
            stock
        }
    ) {
        try {
            const { data } = await Http.instance.post("/bo/references", {
                size,
                color,
                price,
                stock,
                images,
                barcode,
                colorRef,
                productId,
                priceOffer,
                alwaysStock,
                priceMerchant,
                minUnitsForPriceMerchants
            });
            return data;
        } catch (errorAxios) {
            const parseError = handleErrorAxios(errorAxios, dispatch);
            commit("SET_ERROR_PRODUCTS", parseError);
            throw parseError;
        }
    },
    async updateReference(
        { commit, dispatch },
        {
            active,
            alwaysStock,
            barcode,
            color,
            colorRef,
            cost,
            id,
            images,
            minUnitsForPriceMerchants,
            price,
            priceMerchant,
            priceOffer,
            size,
            stock
        }
    ) {
        try {
            const { data } = await Http.instance.patch("/bo/references", {
                id,
                size,
                cost,
                color,
                price,
                stock,
                images,
                active,
                barcode,
                colorRef,
                priceOffer,
                alwaysStock,
                minUnitsForPriceMerchants,
                priceMerchant
            });

            return data;
        } catch (errorAxios) {
            const parseError = handleErrorAxios(errorAxios, dispatch);
            commit("SET_ERROR_PRODUCTS", parseError);
            throw parseError;
        }
    },
    async setDefaultReference(
        { commit, dispatch },
        { productId, referenceId }
    ) {
        try {
            await Http.instance.put("/bo/references/default", {
                productId,
                referenceId
            });
        } catch (errorAxios) {
            const parseError = handleErrorAxios(errorAxios, dispatch);
            commit("SET_ERROR_PRODUCTS", parseError);
            throw parseError;
        }
    },
    async deleteReference({ commit, dispatch }, { referenceId }) {
        try {
            await Http.instance.delete(`/bo/references/${referenceId}`);
        } catch (errorAxios) {
            const parseError = handleErrorAxios(errorAxios, dispatch);
            commit("SET_ERROR_PRODUCTS", parseError);
            throw parseError;
        }
    },
    async listReferenceByBarcode({ commit, dispatch }, { barcode }) {
        try {
            const { data } = await Http.instance.get(
                `/bo/references/barcode/${barcode}`
            );
            let references = data.items;
            references = references.map((item) => ({
                ...item.product,
                productId: item.product.id,
                ...formatReference(item)
            }));
            return references;
        } catch (errorAxios) {
            const parseError = handleErrorAxios(errorAxios, dispatch);
            commit("SET_ERROR_PRODUCTS", parseError);
            throw parseError;
        }
    },
    async listHighlightReferences({ commit, dispatch }, { lineSlug, type }) {
        try {
            const { data } = await Http.instance.get(
                "/bo/references/highlight",
                {
                    params: {
                        type,
                        lineSlug
                    }
                }
            );
            return data.items;
        } catch (errorAxios) {
            const parseError = handleErrorAxios(errorAxios, dispatch);
            commit("SET_ERROR_PRODUCTS", parseError);
            throw parseError;
        }
    },
    async updateHighlightReferences(
        { commit, dispatch },
        { lineSlug, referencesIds, type }
    ) {
        try {
            const { data } = await Http.instance.put(
                "/bo/references/highlight",
                {
                    lineSlug,
                    type,
                    referencesIds
                }
            );
            return data;
        } catch (errorAxios) {
            const parseError = handleErrorAxios(errorAxios, dispatch);
            commit("SET_ERROR_PRODUCTS", parseError);
            throw parseError;
        }
    }
};

export default actions;
