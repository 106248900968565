import store from "@/store/index";
import axios from "axios";

import { ENABLED_GOOGLE_AUTH, MARKETPLACE_API_URL } from "@/common/constants";

import { version } from "../../../package.json";

class Http {
    constructor() {
        this.axios = axios.create();
        this.axios.defaults.baseURL = MARKETPLACE_API_URL;
        this.axios.interceptors.request.use(
            async function (config) {
                const token = store.state.auth.accessToken;
                if (token) {
                    config.headers.Authorization = `Bearer ${token}`;
                }
                config.headers.Version = version;
                return config;
            },
            function (error) {
                return Promise.reject(error);
            }
        );
    }
    static instance = new Http();
    get = async (url, options) => {
        await this.refreshToken();
        return this.axios.get(url, options);
    };
    post = async (url, body, options = {}) => {
        await this.refreshToken();
        return this.axios.post(url, body, options);
    };
    patch = async (url, body, options = {}) => {
        await this.refreshToken();
        return this.axios.patch(url, body, options);
    };
    put = async (url, body, options = {}) => {
        await this.refreshToken();
        return this.axios.put(url, body, options);
    };
    delete = async (url, body, options = {}) => {
        await this.refreshToken();
        return this.axios.delete(url, body, options);
    };

    refreshToken = async () => {
        if (!ENABLED_GOOGLE_AUTH) return;
        await store.dispatch("auth/refreshAndValidateToken");
    };
}

export default Http;
