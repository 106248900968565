import { handleErrorAxios } from "@/common/lib/formatter";
import Http from "@/common/lib/http";

const actions = {
    async listFilesPaginated(
        { commit, dispatch },
        { complete, keyword, page }
    ) {
        try {
            const { data } = await Http.instance.get("/bo/files", {
                params: { page, complete, keyword }
            });
            return data;
        } catch (errorAxios) {
            const parseError = handleErrorAxios(errorAxios, dispatch);
            commit("SET_ERROR_FILES", parseError);
            throw parseError;
        }
    },
    // post for cache
    async getEndPointUploadFile(
        { commit, dispatch },
        { alt, fileName, fileSize, type }
    ) {
        try {
            const { data } = await Http.instance.post("/bo/files/upload", {
                fileName,
                fileSize,
                type,
                alt
            });
            commit("SET_URL_UPLOAD", data.urlSigned);
            return data;
        } catch (errorAxios) {
            const parseError = handleErrorAxios(errorAxios, dispatch);
            commit("SET_ERROR_FILES", parseError);
            throw parseError;
        }
    },
    async uploadFile({ commit, dispatch }, { file, url }) {
        try {
            const res = await fetch(url, {
                method: "PUT",
                headers: {
                    "Content-Type": "multipart/form-data",
                    "Cache-Control": "max-age=31536000"
                },
                body: file
            });
            const status = res.status;
            return { status };
        } catch (errorAxios) {
            const parseError = handleErrorAxios(errorAxios, dispatch);
            commit("SET_ERROR_FILES", parseError);
            throw parseError;
        }
    },
    async getFileById({ commit, dispatch, state }, { id }) {
        const file = state.files.find((item) => item.id === id);
        if (file) {
            return {
                ...file,
                createdAt: "2021-10-26T00:27:59.146Z",
                updatedAt: "2021-10-26T00:27:59.146Z"
            };
        }

        try {
            const { data } = await Http.instance.get(`/bo/files/${id}`);

            return data;
        } catch (errorAxios) {
            const parseError = handleErrorAxios(errorAxios, dispatch);
            commit("SET_ERROR_FILES", parseError);
            throw parseError;
        }
    },
    async createFile({ commit, dispatch }, { alt, id, type, url }) {
        try {
            const { data } = await Http.instance.post("/bo/files", {
                id,
                url,
                type,
                alt
            });

            const file = {
                url,
                alt,
                type,
                ...data
            };
            return file;
        } catch (errorAxios) {
            const parseError = handleErrorAxios(errorAxios, dispatch);
            commit("SET_ERROR_FILES", parseError);
            throw parseError;
        }
    },
    async updateFile({ commit, dispatch }, { alt, id, size }) {
        try {
            const { data } = await Http.instance.patch("/bo/files", {
                id,
                alt,
                size
            });

            return data;
        } catch (errorAxios) {
            const parseError = handleErrorAxios(errorAxios, dispatch);
            commit("SET_ERROR_FILES", parseError);
            throw parseError;
        }
    },
    async deleteFile({ commit, dispatch }, { definitely, id }) {
        try {
            const { data } = await Http.instance.delete(
                `/bo/files/${id}?definitely=${definitely}`
            );

            return data;
        } catch (errorAxios) {
            const parseError = handleErrorAxios(errorAxios, dispatch);
            commit("SET_ERROR_FILES", parseError);
            throw parseError;
        }
    }
};

export default actions;
