import { handleErrorAxios } from "@/common/lib/formatter";
import Http from "@/common/lib/http";

const actions = {
    async listAllRoles({ commit, dispatch }, { type }) {
        try {
            const { data } = await Http.instance.get("/bo/roles/all", {
                params: { type }
            });
            return data.items;
        } catch (errorAxios) {
            const parseError = handleErrorAxios(errorAxios, dispatch);
            commit("SET_ERROR_ROLES", parseError);
            throw parseError;
        }
    },

    async getRoleBySlug({ commit, dispatch }, { slug }) {
        try {
            const { data } = await Http.instance.get(`/bo/roles/${slug}`);
            return data;
        } catch (errorAxios) {
            const parseError = handleErrorAxios(errorAxios, dispatch);
            commit("SET_ERROR_ROLES", parseError);
            throw parseError;
        }
    },

    async syncRoles({ commit, dispatch }) {
        try {
            const { data } = await Http.instance.post("/bo/roles/sync");
            const { complete } = data;
            commit("SET_ROLES", complete);
            return complete;
        } catch (errorAxios) {
            const parseError = handleErrorAxios(errorAxios, dispatch);
            commit("SET_ERROR_ROLES", parseError);
            throw parseError;
        }
    }
};

export default actions;
