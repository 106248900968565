const mutations = {
    ADD_ALL_CATEGORIES(state, payload) {
        const auxItems = [...state.categoriesAll, ...payload];
        const diffItems = auxItems.filter(
            (value, index, self) =>
                self.findIndex((item) => item.id === value.id) === index
        );
        state.categoriesAll = diffItems;
    },
    SET_ERROR_CATEGORIES(state, payload) {
        state.error = payload;
    }
};

export default mutations;
