const mutations = {
    SET_USER(state, payload) {
        state.user = payload;
    },
    SET_USER_FILTERS(state, payload) {
        state.usersFilter = payload;
    },
    SET_USER_SEARCH(state, payload) {
        state.usersSearch = payload;
    },
    SET_ERROR_USERS(state, payload) {
        state.error = payload;
    }
};

export default mutations;
