import { ENABLED_ECOMMERCE_OPTIONS, SITE_TYPE } from "@/common/constants";

export const sidebarItems = [
    {
        id: "orders",
        name: "Ordenes",
        icon: "💳",
        type: "group",
        scope: "order",
        items: [
            {
                name: "Ordenes",
                id: "orders",
                icon: "🔷",
                scope: "order:List",
                url: "/app/orders",
                hide: !ENABLED_ECOMMERCE_OPTIONS
            },
            {
                name: "Historial de ventas",
                id: "orders/own",
                icon: "🔷",
                scope: "order:ListOwn",
                url: "/app/orders/own"
            },
            {
                name: "Venta rápida",
                id: "orders/record",
                icon: "🔷",
                scope: "sale:Create",
                url: "/app/orders/record"
            },
            {
                name: "Registrar venta",
                id: "orders/new-in-merchant",
                icon: "🔷",
                scope: "sale:Create",
                url: "/app/orders/new-in-merchant"
            },
            {
                name: `Registrar venta ${SITE_TYPE}`,
                id: "orders/new",
                icon: "🔷",
                scope: "sale:CreateWithGlobalPrices",
                url: "/app/orders/new"
            },
            {
                name: "Grupos de ordenes",
                id: "orders-groups",
                icon: "🔷",
                scope: "orderGroup:List",
                url: "/app/orders-groups"
            },
            {
                name: "Referencias pendientes",
                id: "orders/references",
                icon: "🔷",
                scope: "order:ListReferences",
                url: "/app/orders/references"
            },
            {
                name: "Ventas por producto",
                id: "orders/products",
                icon: "🔷",
                scope: "sale:ListByProduct",
                url: "/app/orders/product"
            }
        ]
    },
    {
        id: "inventories",
        name: "Inventarios",
        icon: "🧮",
        scope: "inventory",
        type: "group",
        items: [
            {
                name: "Inventario",
                id: "inventories_list",
                icon: "🔷",
                scope: "inventory:Get",
                url: "/app/inventories"
            },
            {
                name: "Registrar inventario",
                id: "inventories_new",
                icon: "🔷",
                scope: "inventory:Create",
                url: "/app/inventories/new"
            },
            {
                name: "Carga masiva de inventario",
                id: "inventories_massive",
                icon: "🔷",
                scope: "inventory:Create",
                url: "/app/inventories/massive"
            },
            {
                name: "Inventario histórico",
                id: "inventories_list",
                icon: "🔷",
                scope: "inventory:ListDate",
                url: "/app/inventories/history"
            }
        ]
    },
    {
        id: "merchants",
        name: "Operaciones",
        icon: "⚡",
        scope: "merchant",
        type: "group",
        items: [
            {
                name: "Caja",
                id: "box",
                icon: "💰",
                scope: "merchant:GetBox",
                url: "/app/merchant/box"
            },
            {
                name: "Gastos",
                id: "cash_outputs",
                icon: "💸",
                scope: "cashOutput:List",
                url: "/app/cash-outputs"
            },
            {
                name: "Registrar gasto",
                id: "cash_outputs",
                icon: "💸",
                scope: "cashOutput:Create",
                url: "/app/cash-outputs/new"
            },
            {
                name: "Listas de compras",
                id: "supply-lists",
                icon: "📜",
                scope: "supplyList:List",
                url: "/app/supply-lists"
            },
            {
                name: "Nueva lista de compras",
                id: "supply-lists",
                icon: "📜",
                scope: "supplyList:Create",
                url: "/app/supply-lists/new"
            },
            {
                name: "Servicios",
                id: "services",
                icon: "👩‍🏭",
                scope: "merchant:ListServices",
                url: "/app/merchant/services"
            }
        ]
    },
    {
        id: "stores",
        name: "Tiendas",
        icon: "🏪",
        scope: "store",
        type: "group",
        items: [
            {
                name: "Tiendas",
                id: "stores_list",
                icon: "🔷",
                scope: "store:List",
                url: "/app/stores"
            },
            {
                name: "Crear tienda",
                id: "stores_new",
                icon: "🔷",
                scope: "store:Create",
                url: "/app/stores/new"
            },
            {
                name: "Registrar tienda",
                id: "stores_record",
                icon: "🔷",
                scope: "store:CreateOwn",
                url: "/app/stores/record"
            }
        ]
    },
    {
        id: "products",
        name: "Productos",
        icon: "🛍️",
        type: "group",
        scope: "product",
        items: [
            {
                name: "Productos",
                id: "products",
                scope: "product:List",
                icon: "🔷",
                url: "/app/products"
            },
            {
                name: "Búsqueda de productos",
                scope: "product:Search",
                id: "products",
                icon: "🔷",
                url: "/app/products/search"
            },
            {
                name: "Registrar producto",
                id: "products",
                scope: "product:Record",
                icon: "🔷",
                url: "/app/products/record"
            },
            {
                name: "Crear producto",
                id: "products",
                scope: "product:Creates",
                icon: "🔷",
                url: "/app/products/new"
            },
            {
                name: "Carga masiva de productos",
                scope: "product:LoadMassive",
                id: "products",
                icon: "🔷",
                url: "/app/products/massive"
            },
            {
                name: "Referencias destacados",
                id: "products",
                scope: "reference:ListHighlight",
                icon: "🔷",
                url: "/app/products/highlight"
            },
            {
                name: "Tablas logística",
                id: "logistic-configurations",
                scope: "logisticConfiguration:List",
                icon: "🔷",
                url: "/app/logistic-configurations"
            },
            {
                name: "Nueva tabla logística",
                id: "products",
                scope: "logisticConfiguration:Create",
                icon: "🔷",
                url: "/app/logistic-configurations/new"
            }
        ]
    },
    {
        id: "customers",
        name: "Clientes",
        icon: "👩",
        scope: "customer",
        type: "group",
        items: [
            {
                name: "Clientes",
                id: "customers",
                icon: "🔷",
                scope: "customer:List",
                url: "/app/customers"
            },
            {
                name: "Registrar cliente",
                id: "customer_new",
                icon: "🔷",
                scope: "customer:Create",
                url: "/app/customers/new"
            }
        ]
    },
    {
        id: "lines",
        name: "Líneas y sublíneas",
        icon: "➖",
        type: "group",
        scope: "line",
        items: [
            {
                name: "Líneas",
                id: "lines",
                icon: "🔷",
                scope: "",
                url: "/app/lines"
            },
            {
                name: "Registrar línea",
                id: "lines",
                icon: "🔷",
                scope: "line:Create",
                url: "/app/lines/new"
            },
            {
                name: "Sublíneas",
                id: "lines",
                icon: "🔷",
                scope: "",
                url: "/app/sublines"
            },
            {
                name: "Registrar sublínea",
                id: "lines",
                icon: "🔷",
                scope: "subline:Create",
                url: "/app/sublines/new"
            }
        ]
    },
    {
        id: "categories",
        name: "Categorías",
        icon: "🏷️",
        type: "group",
        scope: "category",
        items: [
            {
                name: "Categorías",
                id: "categories",
                scope: "category:List",
                icon: "🔷",
                url: "/app/categories"
            },
            {
                name: "Nueva categoría",
                id: "categories",
                scope: "category:Create",
                icon: "🔷",
                url: "/app/categories/new"
            },
            {
                name: "Categorías destacadas",
                id: "categories",
                scope: "category:ListHighlight",
                icon: "🔷",
                url: "/app/categories/highlight"
            }
        ]
    },
    {
        id: "brands",
        name: "Marcas",
        icon: "🔖",
        type: "group",
        scope: "brand",
        items: [
            {
                name: "Marcas",
                id: "brands",
                icon: "🔷",
                scope: "",
                url: "/app/brands"
            },
            {
                name: "Registrar marca",
                id: "brands",
                icon: "🔷",
                scope: "brand:Create",
                url: "/app/brands/new"
            },
            {
                name: "Marcas destacadas",
                id: "brands",
                icon: "🔷",
                scope: "brand:ListHighlight",
                url: "/app/brands/highlight"
            }
        ]
    },
    {
        id: "warehouses",
        name: "Bodegas",
        icon: "🏬",
        scope: "warehouse",
        type: "group",
        items: [
            {
                name: "Bodegas",
                id: "warehouses_list",
                icon: "🔷",
                scope: "warehouse:List",
                url: "/app/warehouses"
            },
            {
                name: "Nueva bodega",
                id: "warehouses_new",
                icon: "🔷",
                scope: "warehouse:Create",
                url: "/app/warehouses/new"
            }
        ]
    },
    {
        id: "suppliers",
        name: "Provedores",
        icon: "🏭",
        scope: "supplier",
        type: "group",
        items: [
            {
                name: "Provedores",
                id: "suppliers_list",
                icon: "🔷",
                scope: "supplier:List",
                url: "/app/suppliers"
            },
            {
                name: "Nuevo proveedor",
                id: "suppliers_new",
                icon: "🔷",
                scope: "supplier:Create",
                url: "/app/suppliers/new"
            }
        ]
    },
    {
        id: "users",
        name: "Usuarios",
        icon: "👩‍💻",
        scope: "user",
        type: "group",
        items: [
            {
                name: "Colaboradores",
                id: "employees",
                icon: "🔷",
                scope: "user:ListOwn",
                url: "/app/employees"
            },
            {
                name: "Usuarios",
                id: "user_list",
                icon: "🔷",
                scope: "user:List",
                url: "/app/users"
            },
            {
                name: "Roles",
                id: "role_list",
                icon: "🔷",
                scope: "role:List",
                url: "/app/roles"
            }
        ]
    },
    {
        id: "cart",
        name: "Carros de Compras",
        icon: "🛒",
        type: "group",
        scope: "cart",
        items: [
            {
                name: "Carritos",
                id: "cart",
                icon: "🔷",
                scope: "cart:List",
                url: "/app/carts"
            },
            {
                name: "Nuevo Carrito",
                id: "carts",
                icon: "🔷",
                scope: "cart:List",
                url: "/app/carts/new"
            }
        ]
    },
    {
        id: "blogs",
        name: "Blogs",
        icon: "📖",
        scope: "blog",
        type: "group",
        items: [
            {
                name: "Blogs",
                id: "blogs",
                icon: "🔷",
                scope: "blog:List",
                url: "/app/blogs"
            },
            {
                name: "Nuevo blog",
                id: "blogs",
                icon: "🔷",
                scope: "blog:Create",
                url: "/app/blogs/new"
            }
        ]
    },
    {
        id: "banners",
        name: "Banners",
        icon: "🧩",
        scope: "banner",
        type: "group",
        items: [
            {
                name: "Banners",
                id: "banners",
                icon: "🔷",
                scope: "banner:List",
                url: "/app/banners"
            },
            {
                name: "Nuevo banner",
                id: "banners",
                icon: "🔷",
                scope: "banner:Create",
                url: "/app/banners/new"
            }
        ]
    },
    {
        id: "files",
        name: "Archivos",
        icon: "🗂",
        scope: "image",
        type: "group",
        items: [
            {
                name: "Imágenes",
                id: "images",
                icon: "🔷",
                scope: "image:List",
                url: "/app/images"
            },
            {
                name: "Nueva imagen",
                id: "images",
                icon: "🔷",
                scope: "image:Create",
                url: "/app/images/new"
            },
            {
                name: "Archivos",
                id: "files",
                icon: "🔷",
                scope: "file:List",
                url: "/app/files"
            },
            {
                name: "Nuevo Archivo",
                id: "files",
                icon: "🔷",
                scope: "file:Create",
                url: "/app/files/new"
            }
        ]
    }
];

export const sidebarItemsFlat = sidebarItems.flatMap((item) =>
    item.items.map((item2) => ({ ...item2, icon: item.icon }))
);
