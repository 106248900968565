<template>
    <div
        class="container h-screen d-flex align-items-center justify-content-center"
    >
        <div class="col-md-12 text-center">
            <span class="display-1 d-block">404</span>
            <div class="mb-4 lead">La página que buscas no existe 😵.</div>
            <router-link to="/" class="btn btn-link" aria-label="Ir a inicio">
                Vuelve al inicio 🏠
            </router-link>
        </div>
    </div>
</template>

<script>
export default {
    name: "NotFound"
};
</script>
