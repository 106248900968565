import { handleErrorAxios } from "@/common/lib/formatter";
import Http from "@/common/lib/http";

const actions = {
    async listAllBrandsShort({ commit, dispatch, state }, { required }) {
        if (!required) {
            return state.brandsShort;
        }
        try {
            const { data } = await Http.instance.get("/bo/brands/all");
            commit("SET_BRANDS_SHORTS", data.items);
            return data.items;
        } catch (errorAxios) {
            const parseError = handleErrorAxios(errorAxios, dispatch);
            commit("SET_ERROR_COMMONS", parseError);
            throw parseError;
        }
    },
    async listAllLines({ commit, dispatch, state }, { active, required }) {
        if (!required) {
            return state.linesActiveShort;
        }
        try {
            const { data } = await Http.instance.get("/bo/lines/all", {
                params: { active, type: "line" }
            });
            commit("SET_LINES_SHORTS", data.items);
            return data.items;
        } catch (errorAxios) {
            const parseError = handleErrorAxios(errorAxios, dispatch);
            commit("SET_ERROR_COMMONS", parseError);
            throw parseError;
        }
    },
    async listAllLinesQuery({ commit, dispatch }, { active, type }) {
        try {
            const { data } = await Http.instance.get("/bo/lines/all", {
                params: { active, type }
            });
            return data.items;
        } catch (errorAxios) {
            const parseError = handleErrorAxios(errorAxios, dispatch);
            commit("SET_ERROR_COMMONS", parseError);
            throw parseError;
        }
    },
    async listSupplierAll({ commit, dispatch, state }, { active, required }) {
        if (!required) {
            return state.suppliersAll;
        }
        try {
            const { data } = await Http.instance.get("/bo/suppliers/all", {
                params: { active }
            });
            commit("SET_SUPPLIERS_ALL", data.items);
            return data.items;
        } catch (errorAxios) {
            const parseError = handleErrorAxios(errorAxios, dispatch);
            commit("SET_ERROR_COMMONS", parseError);
            throw parseError;
        }
    },
    async listAllLinesAndSublines(
        { commit, dispatch, state },
        { active, keyword, required, type }
    ) {
        if (!required) {
            return state.linesAndSublinesAll.filter((item) => {
                const auxObj = {
                    ...{
                        ...(keyword
                            ? {
                                  keyword: item.name
                                      .toLowerCase()
                                      .includes(keyword.toLowerCase())
                              }
                            : null)
                    },
                    ...{
                        ...(active !== undefined
                            ? {
                                  active: item.active === active
                              }
                            : null)
                    }
                };

                return Object.values(auxObj).every((item2) => item2);
            });
        }
        try {
            const { data } = await Http.instance.get("/bo/lines/all", {
                params: {
                    active,
                    keyword,
                    type
                }
            });
            commit("SET_SUBLINES_SHORT", data.items);
            return data.items;
        } catch (errorAxios) {
            const parseError = handleErrorAxios(errorAxios, dispatch);
            commit("SET_ERROR_COMMONS", parseError);
            throw parseError;
        }
    },
    async listOwnStores({ commit, dispatch, state }, { required }) {
        if (!required) {
            return state.merchantsShort;
        }
        try {
            const { data } = await Http.instance.get("/bo/stores/own");
            commit("SET_MERCHANTS_SHORT", data);
            return data;
        } catch (errorAxios) {
            const parseError = handleErrorAxios(errorAxios, dispatch);
            commit("SET_ERROR_COMMONS", parseError);
            throw parseError;
        }
    },
    async listOwnMerchants({ commit, dispatch, state }, { required }) {
        if (!required && state.merchantsOwn.length) {
            return state.merchantsOwn;
        }
        try {
            const { data } = await Http.instance.get("/bo/merchants/own");
            commit("SET_MERCHANTS_OWN", data.items);
            if (data.items.length === 0) {
                dispatch("auth/applyMerchantScopes", [], { root: true });
            }
            return data.items;
        } catch (errorAxios) {
            const parseError = handleErrorAxios(errorAxios, dispatch);
            commit("SET_ERROR_COMMONS", parseError);
            throw parseError;
        }
    }
};

export default actions;
