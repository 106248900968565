import { handleErrorAxios } from "@/common/lib/formatter";
import Http from "@/common/lib/http";

const actions = {
    async listImages({ commit, dispatch }, { complete, keyword, page, size }) {
        try {
            const { data } = await Http.instance.get("/bo/images", {
                params: { page, complete, keyword, size }
            });
            const images = data.items;
            commit("ADD_IMAGES", images);
            return data;
        } catch (errorAxios) {
            const parseError = handleErrorAxios(errorAxios, dispatch);
            commit("SET_ERROR_IMAGES", parseError);
            throw parseError;
        }
    },
    async getEndPointUploadImage(
        { commit, dispatch },
        { alt, entity, fileName, fileSize, id, size }
    ) {
        try {
            const { data } = await Http.instance.post("/bo/images/upload", {
                fileName,
                fileSize,
                alt,
                size,
                id,
                entity
            });

            commit("SET_URL_UPLOAD", data);
            return data;
        } catch (errorAxios) {
            const parseError = handleErrorAxios(errorAxios, dispatch);
            commit("SET_ERROR_IMAGES", parseError);
            throw parseError;
        }
    },
    async uploadImage({ commit, dispatch }, { file, url }) {
        try {
            const res = await fetch(url, {
                method: "PUT",
                headers: {
                    "Content-Type": "multipart/form-data",
                    "Cache-Control": "max-age=31536000"
                },
                body: file
            });
            const status = res.status;
            return { status };
        } catch (errorAxios) {
            const parseError = handleErrorAxios(errorAxios, dispatch);
            commit("SET_ERROR_IMAGES", parseError);
            throw parseError;
        }
    },
    async getImageById({ commit, dispatch }, { id }) {
        try {
            const { data } = await Http.instance.get(`/bo/images/${id}`);

            return data;
        } catch (errorAxios) {
            const parseError = handleErrorAxios(errorAxios, dispatch);
            commit("SET_ERROR_IMAGES", parseError);
            throw parseError;
        }
    },
    async createImage({ commit, dispatch }, { alt, id, size, url }) {
        try {
            const { data } = await Http.instance.post("/bo/images", {
                id,
                url,
                alt,
                size
            });

            const image = {
                url,
                alt,
                size,
                ...data
            };
            return image;
        } catch (errorAxios) {
            const parseError = handleErrorAxios(errorAxios, dispatch);
            commit("SET_ERROR_IMAGES", parseError);
            throw parseError;
        }
    },
    async updateImage({ commit, dispatch }, { alt, id, size }) {
        try {
            const { data } = await Http.instance.patch("/bo/images", {
                id,
                alt,
                size
            });

            return data;
        } catch (errorAxios) {
            const parseError = handleErrorAxios(errorAxios, dispatch);
            commit("SET_ERROR_IMAGES", parseError);
            throw parseError;
        }
    },
    async deleteImage({ commit, dispatch }, { definitely, id }) {
        try {
            const { data } = await Http.instance.delete(
                `/bo/images/${id}?definitely=${definitely}`
            );

            return data;
        } catch (errorAxios) {
            const parseError = handleErrorAxios(errorAxios, dispatch);
            commit("SET_ERROR_IMAGES", parseError);
            throw parseError;
        }
    }
};

export default actions;
