import { handleErrorAxios } from "@/common/lib/formatter";
import Http from "@/common/lib/http";

const actions = {
    async createBlog(
        { commit, dispatch },
        { description, imageId, keywords, shortDescription, slug, title, type }
    ) {
        try {
            const { data } = await Http.instance.post("/bo/blogs", {
                title,
                description,
                shortDescription,
                slug,
                type,
                keywords,
                imageId
            });

            const blog = data;
            return blog;
        } catch (errorAxios) {
            const parseError = handleErrorAxios(errorAxios, dispatch);
            commit("SET_ERROR_BLOGS", parseError);
            throw parseError;
        }
    },

    async listBlogsPaginated({ commit, dispatch }, { complete, page }) {
        try {
            const { data } = await Http.instance.get("/bo/blogs", {
                params: {
                    page,
                    complete
                }
            });
            return data;
        } catch (errorAxios) {
            const parseError = handleErrorAxios(errorAxios, dispatch);
            commit("SET_ERROR_BLOGS", parseError);
            throw parseError;
        }
    },

    async getBlogBySlug({ commit, dispatch }, { slug }) {
        try {
            const { data } = await Http.instance.get(`/bo/blogs/${slug}`);
            return data;
        } catch (errorAxios) {
            const parseError = handleErrorAxios(errorAxios, dispatch);
            commit("SET_ERROR_BLOGS", parseError);
            throw parseError;
        }
    },

    async updateBlog(
        { commit, dispatch },
        {
            description,
            id,
            imageId,
            keywords,
            shortDescription,
            slug,
            title,
            type
        }
    ) {
        try {
            const { data } = await Http.instance.patch("/bo/blogs", {
                id,
                title,
                description,
                keywords,
                shortDescription,
                slug,
                type,
                imageId
            });

            return data;
        } catch (errorAxios) {
            const parseError = handleErrorAxios(errorAxios, dispatch);
            commit("SET_ERROR_BLOGS", parseError);
            throw parseError;
        }
    },
    async publishBlog({ commit, dispatch }, { active, slug, weight }) {
        try {
            const { data } = await Http.instance.put("/bo/blogs/publish", {
                slug,
                active,
                weight
            });
            return data;
        } catch (errorAxios) {
            const parseError = handleErrorAxios(errorAxios, dispatch);
            commit("SET_ERROR_BLOGS", parseError);
            throw parseError;
        }
    },
    async updateBlogsFiles({ commit, dispatch }, { blogSlug, filesIds }) {
        try {
            await Http.instance.patch("/bo/blogs/files", {
                blogSlug,
                filesIds
            });
        } catch (errorAxios) {
            const parseError = handleErrorAxios(errorAxios, dispatch);
            commit("SET_ERROR_BLOGS", parseError);
            throw parseError;
        }
    },
    async updateBlogsProducts({ commit, dispatch }, { blogSlug, productsIds }) {
        try {
            await Http.instance.put("/bo/blogs/products", {
                blogSlug,
                productsIds
            });
        } catch (errorAxios) {
            const parseError = handleErrorAxios(errorAxios, dispatch);
            commit("SET_ERROR_BLOGS", parseError);
            throw parseError;
        }
    },
    async deleteBlog({ commit, dispatch }, { slug }) {
        try {
            await Http.instance.delete(`/bo/blogs/${slug}`);
        } catch (errorAxios) {
            const parseError = handleErrorAxios(errorAxios, dispatch);
            commit("SET_ERROR_BLOGS", parseError);
            throw parseError;
        }
    }
};

export default actions;
