import L from "leaflet";

const iconContainerEmpty = L.icon({
    iconUrl: "/img/logos/short_logo.png",
    iconSize: [32, 32],
    iconAnchor: [0, 0]
});

const lineStyle = {
    weight: 4,
    opacity: 0.8
};

const polygonStyle = {
    fillColor: "#aab4dafb",
    weight: 2,
    opacity: 1,
    color: "#253C99",
    dashArray: "3",
    fillOpacity: 0.7
};

const mapURL = "https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png";

class Map {
    static instance = new Map();
    constructor() {
        this.map = null;
        this.markers = [];
        this.polygons = [];
        this.lines = [];
        this.freeBus = {
            type: "FeatureCollection",
            features: []
        };
    }

    init(map, onClickMap) {
        this.map = map;
        if (onClickMap) this.map.on("click", onClickMap);
        L.tileLayer(mapURL, {
            attribution: "&copy Innovalombia | OpenStreetMap"
        }).addTo(this.map);
        return this;
    }

    selectIcon(type) {
        const icons = {
            default: iconContainerEmpty
        };
        return icons[type] || iconContainerEmpty;
    }

    addLineGlobal(status = "resolved") {
        L.geoJSON(this.freeBus, {
            style: {
                ...lineStyle,
                color: status === "collected" ? "#02913f" : "#ff0000"
            }
        }).addTo(this.map);
    }

    addLine(id = "0", line = [], status = false) {
        const section = L.geoJSON(
            {
                type: "Feature",
                properties: {
                    popupContent: "limpo tracing",
                    underConstruction: true
                },
                geometry: {
                    type: "LineString",
                    coordinates: line
                }
            },
            {
                style: {
                    ...lineStyle,
                    color: status ? "#02913f" : "#ff0000"
                }
            }
        );
        this.lines.push({ id, line: section });
        section.addTo(this.map);
    }

    removeLine(id) {
        const i = this.lines.map((arr) => arr.id).indexOf(id);
        if (!i) return;
        this.lines[i].line.remove();
        this.lines.splice(i, 1);
    }

    removeAllLines() {
        while (this.lines.length > 0) {
            this.lines[0].line.remove();
            this.lines.splice(0, 1);
        }
    }

    setView(coors, zoom = 12) {
        this.map.setView(coors, zoom);
    }

    addMarker(id, [lat, lon], message, type = "default") {
        if (!this.map) return;
        const marker = L.marker([lat, lon], { icon: this.selectIcon(type) });
        this.markers.push({ id, marker });
        if (message) marker.bindPopup(`<p>${message}</p>`).openPopup();
        marker.addTo(this.map).on("click", (e) => {
            this.map.setView(e.target.getLatLng(), 14);
        });
    }

    removeMarker(id) {
        const index = this.markers.map((arr) => arr.id).indexOf(id);
        if (index !== -1) {
            this.markers[index].marker.remove();
            this.markers.splice(index, 1);
        }
    }

    removeAllMarkers() {
        while (this.markers.length > 0) {
            this.markers[0].marker.remove();
            this.markers.splice(0, 1);
        }
    }

    updateMarkerPosition(id, coors) {
        const marker = this.markers.find((item) => item.id === id);
        if (marker) marker.marker.setLatLng(coors);
    }

    addPolygon(id = "0", polygon = [], message = null, styles = null) {
        const area = L.geoJSON(
            {
                type: "Feature",
                properties: {},
                geometry: {
                    type: "Polygon",
                    coordinates: [polygon]
                }
            },
            { style: styles || polygonStyle }
        );
        this.polygons.push({ id, polygon: area });
        if (message) area.bindPopup(`<p>${message}</p>`).openPopup();
        area.addTo(this.map);
    }

    removePolygon(id) {
        const i = this.polygons.map((arr) => arr.id).indexOf(id);
        this.polygons[i].polygon.remove();
        this.polygons.splice(i, 1);
    }

    removeAllPolygons() {
        while (this.polygons.length > 0) {
            this.polygons[0].polygon.remove();
            this.polygons.splice(0, 1);
        }
    }

    locationFound(onLocationFound) {
        this.map.locate({ setView: true, maxZoom: 16 });
        this.map.on("locationfound", onLocationFound);
    }
}

const mapPluginImpl = {};
mapPluginImpl.install = function mapPlugin(Vue) {
    Vue.prototype.$map = Map.instance;
};

export default mapPluginImpl;
