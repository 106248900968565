import { handleErrorAxios } from "@/common/lib/formatter";
import Http from "@/common/lib/http";

const actions = {
    async createCashOutput(
        { commit, dispatch },
        {
            concept,
            merchantId,
            merchantType,
            needDecrease,
            privateFileId,
            provider,
            sourcePay,
            value,
            valuePaid
        }
    ) {
        try {
            const { data } = await Http.instance.post("/bo/cash-outputs", {
                merchantId,
                merchantType,
                sourcePay,
                provider,
                concept,
                value,
                valuePaid,
                needDecrease,
                privateFileId
            });

            return data;
        } catch (errorAxios) {
            const parseError = handleErrorAxios(errorAxios, dispatch);
            commit("SET_ERROR_CASH_OUTPUTS", parseError);
            throw parseError;
        }
    },

    async listCashOutputsPaginated(
        { commit, dispatch },
        { complete, merchantId, merchantType, page, query }
    ) {
        try {
            const { data } = await Http.instance.get(
                `/bo/cash-outputs/${merchantType}/${merchantId}`,
                {
                    params: {
                        ...query,
                        page,
                        complete
                    }
                }
            );

            return data;
        } catch (errorAxios) {
            const parseError = handleErrorAxios(errorAxios, dispatch);
            commit("SET_ERROR_CASH_OUTPUTS", parseError);
            throw parseError;
        }
    },

    async getCashOutputById(
        { commit, dispatch },
        { id, merchantId, merchantType }
    ) {
        try {
            const { data } = await Http.instance.get(
                `/bo/cash-outputs/${merchantType}/${merchantId}/${id}`
            );
            return data;
        } catch (errorAxios) {
            const parseError = handleErrorAxios(errorAxios, dispatch);
            commit("SET_ERROR_CASH_OUTPUTS", parseError);
            throw parseError;
        }
    }
};

export default actions;
