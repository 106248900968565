const mutations = {
    SET_ERROR_INVENTORIES(state, payload) {
        state.error = payload;
    },
    CLEAR_INVENTORIES(state) {
        state.inventories = [];
    }
};

export default mutations;
