import { handleErrorAxios } from "@/common/lib/formatter";
import Http from "@/common/lib/http";

const actions = {
    async createSupplyList(
        { commit, dispatch },
        {
            anonymousReferences,
            concept,
            merchantId,
            merchantType,
            notifyAt,
            provider,
            references
        }
    ) {
        try {
            const { data } = await Http.instance.post("/bo/supply-lists", {
                concept,
                provider,
                merchantId,
                merchantType,
                notifyAt,
                references,
                anonymousReferences
            });
            return data;
        } catch (errorAxios) {
            const parseError = handleErrorAxios(errorAxios, dispatch);
            commit("SET_ERROR_SUPPLY_LISTS", parseError);
            throw parseError;
        }
    },

    async listSupplyLists(
        { commit, dispatch },
        { complete, merchantId, merchantType, page }
    ) {
        try {
            const { data } = await Http.instance.get(
                `/bo/supply-lists/${merchantType}/${merchantId}`,
                {
                    params: {
                        complete,
                        page
                    }
                }
            );
            return data;
        } catch (errorAxios) {
            const parseError = handleErrorAxios(errorAxios, dispatch);
            commit("SET_ERROR_SUPPLY_LISTS", parseError);
            throw parseError;
        }
    },

    async getSupplyListById(
        { commit, dispatch },
        { id, merchantId, merchantType }
    ) {
        try {
            const { data } = await Http.instance.get(
                `/bo/supply-lists/${merchantType}/${merchantId}/${id}`
            );
            return data;
        } catch (errorAxios) {
            const parseError = handleErrorAxios(errorAxios, dispatch);
            commit("SET_ERROR_SUPPLY_LISTS", parseError);
            throw parseError;
        }
    },

    async updateSupplyList(
        { commit, dispatch },
        {
            anonymousReferences,
            concept,
            id,
            merchantId,
            merchantType,
            notifyAt,
            provider,
            references
        }
    ) {
        try {
            const { data } = await Http.instance.patch("/bo/supply-lists", {
                id,
                concept,
                provider,
                merchantId,
                merchantType,
                notifyAt,
                references,
                anonymousReferences
            });

            return data;
        } catch (errorAxios) {
            const parseError = handleErrorAxios(errorAxios, dispatch);
            commit("SET_ERROR_SUPPLY_LISTS", parseError);
            throw parseError;
        }
    },

    async applySupplyList(
        { commit, dispatch },
        { concept, fileId, id, merchantId, merchantType, provider, references }
    ) {
        try {
            const { data } = await Http.instance.post(
                "/bo/supply-lists/apply",
                {
                    id,
                    merchantType,
                    merchantId,
                    fileId,
                    concept,
                    provider,
                    references
                }
            );

            return data;
        } catch (errorAxios) {
            const parseError = handleErrorAxios(errorAxios, dispatch);
            commit("SET_ERROR_SUPPLY_LISTS", parseError);
            throw parseError;
        }
    }
};

export default actions;
