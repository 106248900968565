<template>
    <div class="container py-5">
        <div class="d-flex flex-wrap mb-5">
            <div v-if="$enabledEcommerceOptions" class="col-12 mt-2">
                <h1 class="">
                    <span class="text-success"> Bienvenido Al </span>
                    <br />
                    {{ $siteTitle }}
                </h1>
                <p class="text-justify">
                    Este portal ha sido diseñado para facilitar la gestión del
                    comercio en línea
                    <a :href="$marketplaceUrl" target="_blank" rel="noopener">
                        {{ $marketplaceUrl }}
                    </a>
                    , así como la administración de puntos físicos como bodegas
                    o tiendas.
                </p>
            </div>
            <div v-else class="col-12 mt-2">
                <h1 class="">
                    <span class="text-success"> Bienvenido Al </span>
                    <br />
                    {{ $siteType | transformString("capitalize") }}
                    {{ $siteTitle }}
                </h1>
                <p>
                    En este sistema POS encontrarás todo lo necesario para
                    administrar tu negocio de manera fácil y rápida. A
                    continuación, te presentamos una lista de características
                    muy útiles que encontrarás en el sistema:
                </p>
            </div>

            <div class="col-11 mx-auto">
                <ul class="list-group">
                    <li class="py-2 px-1">Administrar tu inventario.</li>
                    <li class="py-2 px-1">
                        Registrar tus ventas, con o sin productos.
                    </li>
                    <li class="py-2 px-1">
                        Llevar un registro del dinero en caja.
                    </li>
                    <li class="py-2 px-1">Crear una o más tiendas.</li>
                    <li class="py-2 px-1">
                        Puedes crear servicios y registrarlos en tus ventas
                        fácilmente.
                    </li>
                    <li class="py-2 px-1">Registrar gastos.</li>
                    <li class="py-2 px-1">
                        Crear listas de compras para tener una visión clara de
                        los productos que necesitas abastecer.
                    </li>
                    <li class="py-2 px-1">Llevar control de precios.</li>
                    <li class="py-2 px-1">
                        Busqueda de productos con scanner laser.
                    </li>
                    <li class="py-2 px-1">Generación de guias de entrega.</li>
                    <li class="py-2 px-1">
                        Generar vouchers de pago con códigos de barra para facil
                        busqueda.
                    </li>
                    <fragment v-if="$enabledEcommerceOptions">
                        <li class="py-2 px-1">
                            Administrar productos, marcas, categorías.
                        </li>
                        <li class="py-2 px-1">
                            Proporciona a tus empleados acceso para que puedan
                            asociar y consultar sus ventas, de manera que puedan
                            calcular las comisiones correspondientes.
                        </li>
                        <li class="py-2 px-1">
                            Habilitar tiendas para que haga entregas de pedidos
                            en línea a través de la tienda en línea.
                        </li>
                        <li class="py-2 px-1">
                            Todo tipo de operaciones comunes en el comercio
                            electrónico.
                        </li>
                    </fragment>
                </ul>
            </div>

            <div class="col-12 d-flex justify-content-center mt-3">
                <vs-button size="large" @click="$router.push('/login')">
                    Iniciar 🚀
                </vs-button>
            </div>
        </div>
    </div>
</template>

<script>
import {
    BUSINESS_NAME,
    MARKETPLACE_PORTAL_SLOGAN,
    MARKETPLACE_PORTAL_TITLE,
    MARKETPLACE_PORTAL_URL
} from "@/common/constants";

export default {
    name: "OnboardingPageIndex",
    data: () => ({
        $businessName: BUSINESS_NAME
    }),
    metaInfo: {
        title: `${MARKETPLACE_PORTAL_TITLE} | ${MARKETPLACE_PORTAL_SLOGAN}`,
        meta: [
            {
                name: "description",
                content: `${MARKETPLACE_PORTAL_TITLE} | ${MARKETPLACE_PORTAL_SLOGAN}`
            },
            {
                name: "og:image:secure_url",
                content: `${MARKETPLACE_PORTAL_URL}/img/index.jpg`
            },
            { name: "og:image:height", content: "627" },
            { name: "og:image:width", content: "1200" },
            {
                name: "og:image:alt",
                content: "Administra tu negocio online fácil y gratis"
            },
            { name: "og:image:type", content: "image/jpg" }
        ]
    },
    mounted() {
        document.querySelector("#father").classList.remove("dark-mode");
        this.$vs.setColor("background", "#f3f5f4");
        this.$vs.setColor("primary", "#253C99");
        this.$vs.setColor("text", "#2C3E50");
    }
};
</script>
