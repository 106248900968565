import { EMPTY_MONEY_CONFIG } from "@/common/lib/money";

const state = {
    brandsShort: [],
    linesActiveShort: [],
    merchantsShort: [],
    suppliersAll: [],
    linesAndSublinesAll: [],
    error: null,
    merchantsOwn: [],
    selectedMerchant: {
        name: "",
        address: "",
        merchantType: "",
        merchantId: null,
        moneyConfig: EMPTY_MONEY_CONFIG
    },
    isEnabledServiceSearch: false
};

export default state;
