export const setApiURL = (
    operationMode = "marketplace",
    apiURL = "http://localhost:3333"
) => {
    if (operationMode === "marketplace") {
        return apiURL;
    }
    const host = window.location.host;
    const url = host.split(":")[0];
    return `http://${url}:3333`;
};
