import { handleErrorAxios } from "@/common/lib/formatter";
import Http from "@/common/lib/http";

const actions = {
    async listUsersMerchant(
        { commit, dispatch },
        { merchantId, merchantType }
    ) {
        try {
            const { data } = await Http.instance.get(
                `/bo/users/own/${merchantType}/${merchantId}`
            );
            return data.items;
        } catch (errorAxios) {
            const parseError = handleErrorAxios(errorAxios, dispatch);
            commit("SET_ERROR_USERS", parseError);
            throw parseError;
        }
    },
    async updateUsersMerchant(
        { commit, dispatch },
        { merchantId, merchantType, users }
    ) {
        try {
            await Http.instance.put(
                `/bo/users/own/${merchantType}/${merchantId}`,
                {
                    users
                }
            );
        } catch (errorAxios) {
            const parseError = handleErrorAxios(errorAxios, dispatch);
            commit("SET_ERROR_USERS", parseError);
            throw parseError;
        }
    },
    async listUsersPaginated({ commit, dispatch }, { complete, page, query }) {
        try {
            const { data } = await Http.instance.get("/bo/users", {
                params: { ...query, page, complete }
            });
            const items = data.items;
            return { ...data, items };
        } catch (errorAxios) {
            const parseError = handleErrorAxios(errorAxios, dispatch);
            commit("SET_ERROR_USERS", parseError);
            throw parseError;
        }
    },
    async listUsersByRolePaginated({ commit, dispatch }, { page, role }) {
        try {
            const { data } = await Http.instance.get("/bo/users/roles", {
                params: { page, role }
            });

            return data;
        } catch (errorAxios) {
            const parseError = handleErrorAxios(errorAxios, dispatch);
            commit("SET_ERROR_USERS", parseError);
            throw parseError;
        }
    },
    async listAllUsers({ commit, dispatch, state }, { keyword, required }) {
        const filterUsersLocalFn = (users = []) => {
            return users.filter((item) => {
                const auxObj = {
                    ...{
                        ...(keyword
                            ? {
                                  keyword:
                                      item.firstName
                                          .toLowerCase()
                                          .includes(keyword.toLowerCase()) ||
                                      item.lastName
                                          .toLowerCase()
                                          .includes(keyword.toLowerCase())
                              }
                            : null)
                    }
                };
                return Object.values(auxObj).every((item2) => item2);
            });
        };

        const usersFilter = filterUsersLocalFn(state.usersSearch);

        commit("SET_USER_FILTERS", usersFilter);
        if (!required) {
            return;
        }
        try {
            const { data } = await Http.instance.get("/bo/users/all", {
                params: { keyword }
            });

            const diffUsers = [...data.items, ...state.usersSearch].filter(
                (value, index, self) =>
                    self.findIndex((item) => item.id === value.id) === index
            );
            commit("SET_USER_SEARCH", diffUsers);
            commit("SET_USER_FILTERS", filterUsersLocalFn(diffUsers));
            return;
        } catch (errorAxios) {
            const parseError = handleErrorAxios(errorAxios, dispatch);
            commit("SET_ERROR_USERS", parseError);
            throw parseError;
        }
    },
    async getUserById({ commit, dispatch }, { id }) {
        try {
            const { data } = await Http.instance.get(`/bo/users/${id}`);
            return data;
        } catch (errorAxios) {
            const parseError = handleErrorAxios(errorAxios, dispatch);
            commit("SET_ERROR_USERS", parseError);
            throw parseError;
        }
    },
    async getUserScopesById({ commit, dispatch }, { id }) {
        try {
            const { data } = await Http.instance.get(`/bo/users/${id}/scopes`);
            return data.items;
        } catch (errorAxios) {
            const parseError = handleErrorAxios(errorAxios, dispatch);
            commit("SET_ERROR_USERS", parseError);
            throw parseError;
        }
    },
    async searchCustomers(
        { commit, dispatch },
        { email, identificationNumber }
    ) {
        try {
            const { data } = await Http.instance.get("/bo/users/search", {
                params: {
                    email,
                    identificationNumber
                }
            });
            return data.items;
        } catch (errorAxios) {
            const parseError = handleErrorAxios(errorAxios, dispatch);
            commit("SET_ERROR_USERS", parseError);
            throw parseError;
        }
    },
    async updateUser(
        { commit, dispatch },
        {
            address,
            addressComplement,
            cityCode,
            firstName,
            id,
            identificationNumber,
            identificationType,
            lastName,
            needOnboarding,
            neighborhood,
            phone
        }
    ) {
        try {
            const { data } = await Http.instance.patch("/bo/users", {
                address,
                addressComplement,
                cityCode,
                firstName,
                id,
                identificationNumber,
                identificationType,
                lastName,
                needOnboarding,
                neighborhood,
                phone
            });
            return data;
        } catch (errorAxios) {
            const parseError = handleErrorAxios(errorAxios, dispatch);
            commit("SET_ERROR_USERS", parseError);
            throw parseError;
        }
    },
    async updateUserMerchants({ commit, dispatch }, { merchants, userId }) {
        try {
            const { data } = await Http.instance.put("/bo/users/merchants", {
                userId,
                merchants
            });
            return data;
        } catch (errorAxios) {
            const parseError = handleErrorAxios(errorAxios, dispatch);
            commit("SET_ERROR_USERS", parseError);
            throw parseError;
        }
    },
    async updateUserRoles({ commit, dispatch }, { rolesSlugs, userId }) {
        try {
            const { data } = await Http.instance.patch("/bo/users/roles", {
                rolesSlugs,
                userId
            });
            return data;
        } catch (errorAxios) {
            const parseError = handleErrorAxios(errorAxios, dispatch);
            commit("SET_ERROR_USERS", parseError);
            throw parseError;
        }
    },
    async updateUserRepositories({ commit, dispatch }, { merchants, userId }) {
        try {
            const { data } = await Http.instance.put("/bo/users/merchants", {
                merchants,
                userId
            });

            return data;
        } catch (errorAxios) {
            const parseError = handleErrorAxios(errorAxios, dispatch);
            commit("SET_ERROR_USERS", parseError);
            throw parseError;
        }
    },
    async deleteUser({ commit, dispatch }, { userId }) {
        try {
            await Http.instance.delete(`/bo/users/${userId}`);
        } catch (errorAxios) {
            const parseError = handleErrorAxios(errorAxios, dispatch);
            commit("SET_ERROR_USERS", parseError);
            throw parseError;
        }
    }
};

export default actions;
