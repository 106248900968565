import {
    generateHomeCards,
    generateSidebarAdminByScopes,
    generateSidebarBasicByScopes
} from "@/common/lib/scopes";

const mutations = {
    UPDATED_SIDEBAR_STATE(state, payload) {
        state.activeSidebar = payload;
    },
    UPDATED_LOADING_STATE(state, payload) {
        state.loading = payload;
    },
    UPDATED_ACTIVE_DARK_MODE(state, payload) {
        state.activeDark = payload;
    },
    SET_SIDEBAR_ITEMS(state, payload) {
        state.sidebarItems = [...payload];
    },
    CLEAR_SIDEBAR(state) {
        state.sidebarItems = [];
    },
    APPLY_SIDEBAR_ITEMS(state, { hasAdminRole, scopes }) {
        state.sidebarItems = hasAdminRole
            ? generateSidebarAdminByScopes(scopes)
            : generateSidebarBasicByScopes(scopes);
    },
    APPLY_HOME_CARDS_LINKS(state, scopes) {
        const { cardLinksActive, cardLinksAvailable } =
            generateHomeCards(scopes);
        state.cardLinksAvailable = cardLinksAvailable;
        state.cardLinksActive = cardLinksActive;
    }
};

export default mutations;
