<template>
    <div class="d-flex justify-content-center align-items-center h-screen">
        <strong>Cargando...</strong>
    </div>
</template>

<script>
import { mapActions } from "vuex";

export default {
    name: "CallbackPage",
    async mounted() {
        this.validateAuth();
    },
    methods: {
        ...mapActions("auth", ["getAndApplyCurrentSession"]),
        ...mapActions("commons", ["listOwnMerchants"]),
        async validateAuth() {
            try {
                const { needOnboarding } =
                    await this.getAndApplyCurrentSession();
                if (needOnboarding) {
                    this.pushRoute("/onboarding");
                    return;
                }
                await this.listOwnMerchants({
                    required: true
                });
                this.pushRoute("/app");
            } catch (error) {
                this.pushRoute("/login");
                console.error(error);
            }
        },
        pushRoute(route) {
            setTimeout(() => {
                this.$router.push(route);
            }, 100);
        }
    }
};
</script>
