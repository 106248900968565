const mutations = {
    SET_ERROR_IMAGES(state, payload) {
        state.error = payload;
    },
    ADD_IMAGES(state, payload) {
        const auxItems = [...state.images, ...payload];
        const diffItems = auxItems.filter(
            (value, index, self) =>
                self.findIndex((item) => item.id === value.id) === index
        );

        state.images = [...diffItems];
    },
    SET_URL_UPLOAD(state, payload) {
        state.urlUpload = payload;
    }
};

export default mutations;
