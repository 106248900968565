import { handleErrorAxios } from "@/common/lib/formatter";
import Http from "@/common/lib/http";

const _parseDeliveryConfigurationsByCityCodeObjectToArray = (object) => {
    return Object.keys(object).map((cityCode) => {
        const configurationRaw = object[cityCode];
        const configuration = Object.keys(configurationRaw).map((key) => {
            const deliveryConfiguration = configurationRaw[key];
            return { min: Number(key), ...deliveryConfiguration };
        });
        return {
            cityCode,
            configuration
        };
    });
};

const _parseDeliveryConfigurationsByCityCodeArrayToObject = (array) => {
    return array.reduce((accumulator, item) => {
        const temp = { ...accumulator };
        const keys = item.configuration.reduce((accumulator2, item2) => {
            const temp2 = { ...accumulator2 };
            temp2[item2.min] = {
                deliveryPrice: item2.deliveryPrice,
                deliveryHours: item2.deliveryHours
            };
            return temp2;
        }, {});
        temp[item.cityCode] = keys;
        return temp;
    }, {});
};

const actions = {
    async listAllLogisticConfigurations({ commit, dispatch }) {
        try {
            const { data } = await Http.instance.get(
                "/bo/logistic-configurations/all",
                {
                    params: {}
                }
            );
            return data.items;
        } catch (errorAxios) {
            const parseError = handleErrorAxios(errorAxios, dispatch);
            commit("SET_ERROR_LOGISTIC_CONFIGURATION", parseError);
            throw parseError;
        }
    },
    async listLogisticConfigurationsPaginated(
        { commit, dispatch },
        { complete, page }
    ) {
        try {
            const { data } = await Http.instance.get(
                "/bo/logistic-configurations",
                {
                    params: { page, complete }
                }
            );
            return data;
        } catch (errorAxios) {
            const parseError = handleErrorAxios(errorAxios, dispatch);
            commit("SET_ERROR_LOGISTIC_CONFIGURATION", parseError);
            throw parseError;
        }
    },
    async getLogisticConfigurationBySlug({ commit, dispatch }, { slug }) {
        try {
            const { data } = await Http.instance.get(
                `/bo/logistic-configurations/${slug}`
            );
            return {
                ...data,
                deliveryConfigurationsByCityCode:
                    _parseDeliveryConfigurationsByCityCodeObjectToArray(
                        data.deliveryConfigurationsByCityCode
                    )
            };
        } catch (errorAxios) {
            const parseError = handleErrorAxios(errorAxios, dispatch);
            commit("SET_ERROR_LOGISTIC_CONFIGURATION", parseError);
            throw parseError;
        }
    },
    async createLogisticConfiguration(
        { commit, dispatch },
        {
            alwaysStockPrepareTime,
            deliveryConfigurationsByCityCode,
            name,
            prepareTime,
            slug
        }
    ) {
        try {
            const { data } = await Http.instance.post(
                "/bo/logistic-configurations",
                {
                    slug,
                    name,
                    alwaysStockPrepareTime,
                    prepareTime,
                    deliveryConfigurationsByCityCode:
                        _parseDeliveryConfigurationsByCityCodeArrayToObject(
                            deliveryConfigurationsByCityCode
                        )
                }
            );
            return data;
        } catch (errorAxios) {
            const parseError = handleErrorAxios(errorAxios, dispatch);
            commit("SET_ERROR_LOGISTIC_CONFIGURATION", parseError);
            throw parseError;
        }
    },
    async updateLogisticConfiguration(
        { commit, dispatch },
        {
            alwaysStockPrepareTime,
            deliveryConfigurationsByCityCode,
            name,
            prepareTime,
            slug
        }
    ) {
        try {
            const { data } = await Http.instance.patch(
                "/bo/logistic-configurations",
                {
                    slug,
                    name,
                    alwaysStockPrepareTime,
                    prepareTime,
                    deliveryConfigurationsByCityCode:
                        _parseDeliveryConfigurationsByCityCodeArrayToObject(
                            deliveryConfigurationsByCityCode
                        )
                }
            );

            return data;
        } catch (errorAxios) {
            const parseError = handleErrorAxios(errorAxios, dispatch);
            commit("SET_ERROR_LOGISTIC_CONFIGURATION", parseError);
            throw parseError;
        }
    },
    async simulateLogisticConfiguration(
        { commit, dispatch },
        { cityCode, date, referencePriceOffer, referenceQuantity, slug }
    ) {
        try {
            const { data } = await Http.instance.post(
                `/bo/logistic-configurations/${slug}/simulate`,
                {
                    date,
                    referenceQuantity,
                    referencePriceOffer,
                    cityCode
                }
            );

            return data;
        } catch (errorAxios) {
            const parseError = handleErrorAxios(errorAxios, dispatch);
            commit("SET_ERROR_LOGISTIC_CONFIGURATION", parseError);
            throw parseError;
        }
    }
};

export default actions;
