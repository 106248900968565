export const citiesCodes = {
    11001: "Bogotá D.C.",
    25175: "Chía",
    25430: "Madrid",
    25754: "Soacha",
    25473: "Mosquera",
    25899: "Zipaquirá",
    25269: "Facatativa",
    25286: "Funza",
    25377: "La Calera",
    25214: "Cota",
    25438: "Medellín",
    76001: "Cali",
    co: "Resto de Colombia"
};
