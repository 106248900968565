<template>
    <div class="container h-screen">
        <div
            class="col-md-8 col-lg-6 col-xl-5 col-12 mx-auto py-2 d-flex flex-column justify-content-center h-100"
        >
            <div class="text-center py-lg-3 py-2 shadow bg-body rounded">
                <h1 class="h3 text-success m-0">
                    {{ $siteTitle }}
                </h1>
                <router-link to="/">
                    <small class="m-0">By Innovalombia</small>
                </router-link>
            </div>
            <img
                src="/img/logos/pet_logo.png"
                loading="lazy"
                class="mx-auto my-2"
                :alt="`Inicia sesión en ${$siteTitle}`"
            />

            <div
                v-if="showFederatedSignIn"
                class="d-flex flex-column align-items-center justify-content-center w-100 h-44 px-2"
            >
                <vs-button size="large" block border @click="loginGoogle">
                    <img
                        src="@/assets/images/google.svg"
                        height="15"
                        width="15"
                        alt="reload img"
                    />
                    &nbsp; &nbsp;Continua con Google
                </vs-button>
            </div>

            <div v-else class="px-2">
                <div v-if="!!error" class="alert alert-danger">
                    {{ error }}
                </div>

                <div class="form-group py-2">
                    <vs-input
                        id="user"
                        v-model="user"
                        type="text"
                        name="user"
                        :disabled="isLoading"
                        label="Correo"
                        state="primary"
                        class="input-theme"
                        :class="!validUser ? '' : 'border-custom'"
                        aria-label="Correo"
                        icon-after
                        @blur="introUser = true"
                        @input="lowerCaseUser"
                        @keyup.enter="loginPassword"
                    >
                        <template #icon> 🐵 </template>
                        <template #message-danger>
                            <div v-show="!validUser && introUser">
                                Ingrese correo o usuario valido
                            </div>
                        </template>
                    </vs-input>
                </div>
                <div class="form-group py-2">
                    <vs-input
                        v-model="password"
                        type="password"
                        label="Contraseña"
                        state="primary"
                        class="input-theme"
                        :class="!validPassword ? '' : 'border-custom'"
                        aria-label="Contraseña"
                        autocomplete="off"
                        :visible-password="showPass"
                        :disabled="isLoading"
                        icon-after
                        @click-icon="showPass = !showPass"
                        @blur="introPassword = true"
                        @keyup.enter="loginPassword"
                    >
                        <template #icon>
                            <span>{{ showPass ? "🙉" : "🙈" }}</span>
                        </template>
                        <template #message-danger>
                            <div v-show="!validPassword && introPassword">
                                Ingrese una contraseña valida
                            </div>
                        </template>
                    </vs-input>
                </div>
                <vs-button
                    :disabled="!isValidInfo"
                    :loading="isLoading"
                    block
                    @click="loginPassword"
                >
                    Iniciar Sesion
                </vs-button>
            </div>

            <div
                v-if="$data.$showEnabledGoogleAuth"
                class="d-flex justify-content-center my-2"
            >
                <button
                    class="font-weight-bold btn-a"
                    aria-label="Cambiar opción de inicio de sesión"
                    @click="showFederatedSignIn = !showFederatedSignIn"
                >
                    {{
                        showFederatedSignIn
                            ? "Inicia con contraseña"
                            : "Inicia con redes"
                    }}
                </button>
            </div>
        </div>

        <div class="config p-2 border-top border-left">
            <change-theme />
        </div>
    </div>
</template>

<script>
import { mapActions, mapState } from "vuex";

import {
    ENABLED_GOOGLE_AUTH,
    MARKETPLACE_PORTAL_SLOGAN,
    MARKETPLACE_PORTAL_TITLE,
    MARKETPLACE_PORTAL_URL
} from "@/common/constants";
import { emailRegex, passwordRegex } from "@/common/lib/regex";

import ChangeTheme from "@/components/layout/ChangeTheme.vue";

export default {
    name: "LoginPage",
    components: { ChangeTheme },
    data: () => ({
        user: "",
        password: "",
        $showEnabledGoogleAuth: ENABLED_GOOGLE_AUTH,
        showFederatedSignIn: ENABLED_GOOGLE_AUTH,
        introUser: false,
        introPassword: false,
        error: "",
        showPass: false,
        validUser: false,
        validPassword: false,
        isLoading: false
    }),
    metaInfo: {
        title: `${MARKETPLACE_PORTAL_TITLE} | ${MARKETPLACE_PORTAL_SLOGAN}`,
        meta: [
            {
                name: "description",
                content: `${MARKETPLACE_PORTAL_TITLE} | ${MARKETPLACE_PORTAL_SLOGAN}`
            },
            {
                name: "og:image:secure_url",
                content: `${MARKETPLACE_PORTAL_URL}/img/index.jpg`
            },
            { name: "og:image:height", content: "627" },
            { name: "og:image:width", content: "1200" },
            {
                name: "og:image:alt",
                content: "Administra tu negocio online fácil y gratis"
            },
            { name: "og:image:type", content: "image/jpg" }
        ]
    },
    computed: {
        ...mapState("control", ["loading"]),
        isValidInfo() {
            return this.validUser && this.validPassword && !this.loading;
        }
    },
    watch: {
        user(val) {
            this.validUser = emailRegex.test(val);
        },
        password(val) {
            this.validPassword = passwordRegex.test(val);
        }
    },
    async mounted() {},
    methods: {
        ...mapActions("auth", ["signInPassword", "signInGoogle"]),
        lowerCaseUser() {
            this.user = this.user.toLowerCase();
        },
        async loginPassword() {
            if (!this.isValidInfo) return;
            this.isLoading = true;
            try {
                const redirect = await this.signInPassword({
                    email: this.user,
                    password: this.password
                });
                this.$router.push(redirect);
            } catch (error) {
                console.error(error);
                this.error = error.message;
            } finally {
                this.isLoading = false;
            }
        },
        async loginGoogle() {
            this.isLoading = true;
            try {
                await this.signInGoogle();
            } catch (error) {
                this.error = "Ocurrio un error en el inicio con google";
                console.error(error);
            } finally {
                this.isLoading = false;
            }
        }
    }
};
</script>
