import { DEFAULT_IMAGE_URL } from "@/common/constants";

export const formatReference = (reference) => {
    return "images" in reference && reference.images.length > 0
        ? reference
        : {
              ...reference,
              images: [
                  {
                      id: 1,
                      url: DEFAULT_IMAGE_URL,
                      alt: "alt"
                  }
              ]
          };
};
