const accessToken = "";
const tokenExpirationDate = 0;
const user = {
    email: "innovalombia@gmail.com",
    lastName: "Tecno",
    name: "Innovalombia",
    picture: "",
    sub: "b8529984-56ab-4783-9fa9-a14fd7ce9a06"
};
const roles = ["undefined"];
const globalScopes = ["user:GetOwn"];
const merchantScopes = ["user:GetOwn"];
const scopes = ["user:GetOwn"];
const error = {};

const state = {
    tokenExpirationDate,
    hasAdminRole: true,
    accessToken,
    scopes,
    merchantScopes,
    globalScopes,
    roles,
    error,
    user
};

export default state;
