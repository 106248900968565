const state = {
    loading: false,
    activeSidebar: true,
    activeDark: false,
    error: false,
    sidebarItems: [],
    cardLinksActive: [],
    cardLinksAvailable: []
};

export default state;
