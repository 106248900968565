import { JSEncrypt } from "jsencrypt";

import { PUBLIC_KEY } from "@/common/constants";

class EncryptAdapter {
    encryptAgent;
    constructor() {
        const encryptAgent = new JSEncrypt();
        encryptAgent.setPublicKey(PUBLIC_KEY);
        this.encryptAgent = encryptAgent;
    }

    static instance = new EncryptAdapter();

    /**
     * It takes a string, encrypts it, and returns the encrypted string
     * @param [payload] - The payload to be encrypted.
     * @returns The encrypted payload.
     * @example
     * const encryptedData = EncryptAdapter.instance.parseString(info);
     * const { data } = await Http.instance.post("/bo/auth/security", {
     *  data: encryptedData,
     * });
     */
    parseString(payload = "") {
        return this.encryptAgent.encrypt(payload).toString();
    }
}

export default EncryptAdapter;
