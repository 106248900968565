export const paymentMethods = {
    cashoffline: "Efecty",
    online: "Online",
    bankondelivery: "Transferencia bancaria en entrega",
    bankonpickup: "Transferencia bancaria en recogida",
    cashondelivery: "Pago contra entrega en efectivo",
    cashonpickup: "Pago a la hora de recoger en efectivo",
    daviplataondelivery: "Pago contra entrega con Daviplata",
    daviplataonpickup: "Pago a la hora de recoger con Daviplata",
    nequiondelivery: "Pago contra entrega con Nequi",
    nequionpickup: "Pago a la hora de recoger con Nequi"
    // terminalonpickup: "Pago con datafono"
};

export const paymentMethodsOutput = {
    cashonpickup: "Efectivo",
    daviplataonpickup: "Daviplata",
    nequionpickup: "Nequi",
    terminalonpickup: "Tarjeta"
};

export const paymentMethodsStore = {
    cashonpickup: "Efectivo",
    daviplataonpickup: "Daviplata",
    nequionpickup: "Nequi",
    terminalonpickup: "Datáfono"
};

export const paymentMethodsAbbreviation = {
    cashoffline: "Recaudo",
    online: "Online",
    bankondelivery: "Transferencia bancaria",
    bankonpickup: "Transferencia bancaria",
    cashondelivery: "Efectivo",
    cashonpickup: "Efectivo",
    daviplataondelivery: "Daviplata",
    daviplataonpickup: "Daviplata",
    nequiondelivery: "Nequi",
    nequionpickup: "Nequi"
};

export const paymentMethodsAbbreviationReader = {
    cashoffline: "Recaudo",
    online: "Online",
    bankondelivery: "Transferencia bancaria",
    bankonpickup: "Transferencia bancaria",
    cashondelivery: "Efectivo",
    cashonpickup: "Efectivo",
    daviplataondelivery: "Daviplata",
    daviplataonpickup: "Daviplata",
    nequiondelivery: "Nequi",
    nequionpickup: "Nequi",
    lockerpickup: "Locker",
    terminalonpickup: "Datáfono",
    terminal1onpickup: "Datáfono 1",
    terminal2onpickup: "Datáfono 2",
    terminal3onpickup: "Datáfono 3"
};
