import { handleErrorAxios } from "@/common/lib/formatter";
import Http from "@/common/lib/http";

const actions = {
    async getBoxMerchant({ commit, dispatch }, { merchantId, merchantType }) {
        try {
            const { data } = await Http.instance.get(
                `/bo/merchants/${merchantType}/${merchantId}/box`
            );

            commit(
                "commons/UPDATE_MERCHANTS_GLOBAL",
                { merchantId, merchantType, services: data.moneyConfig },
                { root: true }
            );
            return data.moneyConfig;
        } catch (errorAxios) {
            const parseError = handleErrorAxios(errorAxios, dispatch);
            commit("SET_ERROR_MERCHANTS", parseError);
            throw parseError;
        }
    },
    async updateBoxMerchant(
        { commit, dispatch },
        { merchantId, merchantType, moneyConfig }
    ) {
        try {
            const { data } = await Http.instance.put(
                `/bo/merchants/${merchantType}/${merchantId}/box`,
                {
                    moneyConfig
                }
            );
            commit(
                "commons/UPDATE_MERCHANTS_GLOBAL",
                { merchantId, merchantType, moneyConfig },
                { root: true }
            );
            return data;
        } catch (errorAxios) {
            const parseError = handleErrorAxios(errorAxios, dispatch);
            commit("SET_ERROR_MERCHANTS", parseError);
            throw parseError;
        }
    },
    async listServicesMerchant(
        { commit, dispatch },
        { merchantId, merchantType }
    ) {
        try {
            const { data } = await Http.instance.get(
                `/bo/merchants/${merchantType}/${merchantId}/services`
            );

            commit(
                "commons/UPDATE_MERCHANTS_GLOBAL",
                { merchantId, merchantType, services: data.items },
                { root: true }
            );
            return data.items;
        } catch (errorAxios) {
            const parseError = handleErrorAxios(errorAxios, dispatch);
            commit("SET_ERROR_MERCHANTS", parseError);
            throw parseError;
        }
    },
    async updateServicesMerchant(
        { commit, dispatch },
        { merchantId, merchantType, services }
    ) {
        try {
            const { data } = await Http.instance.put(
                `/bo/merchants/${merchantType}/${merchantId}/services`,
                {
                    services
                }
            );
            commit(
                "commons/UPDATE_MERCHANTS_GLOBAL",
                { merchantId, merchantType, services },
                { root: true }
            );
            return data;
        } catch (errorAxios) {
            const parseError = handleErrorAxios(errorAxios, dispatch);
            commit("SET_ERROR_MERCHANTS", parseError);
            throw parseError;
        }
    }
};

export default actions;
