export const phoneRegex = /^[3][\d]{9,9}$/;

export const emailSimpleRegex = /^[a-z@._]{0,100}$/;

export const emailRegex =
    /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;

export const addressRegex =
    /^(autopista|avenida\scalle|avenida|avenida\scarrera|avenida|carrera|calle|circunvalar|diagonal|kilometro|transversal|autop|avk|avc|av|ac|cra|cr|kra|kr|ak|cll|cl|kr|ccv|dg|km|tv)\s+(([\d]{0,4}|[a-z\s]{1,20})[\s]?[a-z]?[\s]?(bis[\s])?(este|norte|occidente|oeste|sur)?)\s?(#|No|No.|N|[\s])?[\s]?([\d]{0,4}[\s]?[A-Za-z]?)\s?(-|\s)?\s?([\d]{1,4})\s?(este|norte|occidente|oeste|sur)?/i;

export const identificationNumberRegex = /^([0-9]{5,16})(-{1}[0-9]{1})?$/;

export const nameRegex =
    /^([A-ZÁÉÍÓÚÑ]{1,1}[a-záéíóúñ]{2,15})?[\s]?([A-ZÁÉÍÓÚÑ]?[a-záéíóúñ]{2,15})?[\s]?([A-ZÁÉÍÓÚÑ]?[a-záéíóúñ]{2,15})?$/;

export const shortDescriptionRegex = /^.{0,254}$/; // Máximo 254 caracteres

export const titleRegex = /^.{4,254}$/;

export const slugRegex = /^[a-zñ0-9\\-]{3,}$/;

export const skuRegex = /^[A-Z0-9]{5,24}$/;

export const altRegex = /^([\wÀ-ÿÑñ\d\s]{8,128})(\.?)$/;

export const genericStringRegex = /^[\wÀ-ÿÑñ.@#*!\\-\s\d]{3,}/;

export const stringRegex = /^[\dA-Z\wñÑ\u00f1\u00d1]{1,1}$/;

export const passwordRegex = /^[A-Z\wñÑ@#*!\d\u00f1\u00d1]{4,30}$/;

export const userEmailRegex =
    /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))|([a-zñ]+[^A-Z\s%$@^]{2,16})$/;
