<template>
    <router-view />
</template>

<script>
import { mapActions } from "vuex";

import { ENABLED_AUTH } from "@/common/constants";

export default {
    name: "App",
    data: () => ({
        prompt: false
    }),
    mounted() {
        if (this.$workbox) {
            this.$workbox.addEventListener("waiting", () => {
                this.prompt = true;
                this.$vs.notification({
                    title: "Existe una nueva versión de la aplicación",
                    text: "Puedes actualizar la aplicación haciendo click aqui.",
                    duration: "none",
                    position: "bottom-center",
                    onClick: this.updateVersion()
                });
            });
        }
        if (!ENABLED_AUTH) {
            this.applyScopes();
        }
    },
    methods: {
        ...mapActions("auth", ["applyDefaultUser"]),
        async updateVersion() {
            this.prompt = false;
            await this.$workbox.messageSW({ type: "SKIP_WAITING" });
        },
        async applyScopes() {
            this.applyDefaultUser();
        }
    }
};
</script>
