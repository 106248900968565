export const EMPTY_MONEY_CONFIG = {
    cash: 0,
    daviplata: 0,
    nequi: 0,
    terminal: 0,
    terminal1: 0,
    terminal2: 0,
    terminal3: 0
};

const getMoneyConfigFromTypeAndValueNequi = (value) => ({
    ...EMPTY_MONEY_CONFIG,
    nequi: value
});

const getMoneyConfigFromTypeAndValueDaviplata = (value) => ({
    ...EMPTY_MONEY_CONFIG,
    daviplata: value
});

const getMoneyConfigFromTypeAndValueCash = (value) => ({
    ...EMPTY_MONEY_CONFIG,
    cash: value
});

const getMoneyConfigFromTypeAndValueTerminal = (value) => ({
    ...EMPTY_MONEY_CONFIG,
    terminal: value
});

const getMoneyConfigFromTypeAndValueTerminal1 = (value) => ({
    ...EMPTY_MONEY_CONFIG,
    terminal1: value
});

const getMoneyConfigFromTypeAndValueTerminal2 = (value) => ({
    ...EMPTY_MONEY_CONFIG,
    terminal2: value
});

const getMoneyConfigFromTypeAndValueTerminal3 = (value) => ({
    ...EMPTY_MONEY_CONFIG,
    terminal3: value
});

export const getMoneyConfigFromPaymentMethodAndValue = (
    paymentMethod,
    value
) => {
    const moneyConfigs = {
        cashondelivery: getMoneyConfigFromTypeAndValueCash,
        cashonpickup: getMoneyConfigFromTypeAndValueCash,
        daviplataondelivery: getMoneyConfigFromTypeAndValueDaviplata,
        daviplataonpickup: getMoneyConfigFromTypeAndValueDaviplata,
        nequiondelivery: getMoneyConfigFromTypeAndValueNequi,
        nequionpickup: getMoneyConfigFromTypeAndValueNequi,
        terminalonpickup: getMoneyConfigFromTypeAndValueTerminal,
        terminal1onpickup: getMoneyConfigFromTypeAndValueTerminal1,
        terminal2onpickup: getMoneyConfigFromTypeAndValueTerminal2,
        terminal3onpickup: getMoneyConfigFromTypeAndValueTerminal3
    };
    const handler =
        moneyConfigs[paymentMethod] || getMoneyConfigFromTypeAndValueCash;
    return handler(value);
};

export const sumMoneyConfig = (ag1, ag2) => {
    return {
        cash: ag1.cash + ag2.cash,
        daviplata: ag1.daviplata + ag2.daviplata,
        nequi: ag1.nequi + ag2.nequi,
        terminal: ag1.terminal + ag2.terminal,
        terminal1: ag1.terminal1 + ag2.terminal1,
        terminal2: ag1.terminal2 + ag2.terminal2,
        terminal3: ag1.terminal3 + ag2.terminal3
    };
};

export const calculateOrdersPaymentDistribution = (orders = []) => {
    const result = orders.reduce(
        (acc, order) => {
            const money = getMoneyConfigFromPaymentMethodAndValue(
                order.paymentMethod,
                order.total
            );
            const count = sumMoneyConfig(acc, money);
            return { ...count, total: acc.total + order.total };
        },
        { ...EMPTY_MONEY_CONFIG, total: 0 }
    );
    return result;
};
