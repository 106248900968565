const icons = {
    400: "info",
    406: "info"
};

const titles = {
    400: "Revisa la información",
    403: "Error en permisos",
    406: "Operación NO permitida",
    500: "Error interno en el servidor"
};

export const parseErrorAxios = (errorAxios) => {
    console.error("Error Axios 🎃 %o", errorAxios);
    if (typeof errorAxios === "string") {
        return {
            message: errorAxios,
            statusCode: 500,
            icon: "error",
            title: "Error 😥😣"
        };
    }
    let parseError = {
        message: "Internal error / Network error",
        statusCode: 100,
        icon: "error",
        title: "Error 😥😣"
    };
    if ("response" in errorAxios && !!errorAxios.response) {
        const status = errorAxios.response.status || 500;
        parseError = {
            message:
                typeof errorAxios.response.data === "string"
                    ? errorAxios.response.data
                    : "error" in errorAxios.response.data
                    ? errorAxios.response.data.error
                    : JSON.stringify(errorAxios.response.data),
            statusCode: status,
            icon: icons[status] || "error",
            title: titles[status] || `Error código ${status}`
        };
    }
    return parseError;
};

export const handleErrorAxios = (errorAxios, dispatch, redirect = true) => {
    const parseError = parseErrorAxios(errorAxios);
    if (
        [401, 412, 428].includes(parseError.statusCode) &&
        redirect &&
        dispatch
    ) {
        alert(status === 401 ? "Error en sesión" : "Ha expirado tu sesión");
        dispatch("auth/signOut", {}, { root: true });
    }
    return parseError;
};
