import store from "@/store";

import { verifyScope } from "@/common/lib/scopes";

const abilityPluginImpl = {};
const _verifyAccessibility = (scopes, allowedScope) =>
    scopes.includes(allowedScope);

abilityPluginImpl.install = function abilityPlugin(Vue) {
    Vue.prototype.$ability = {
        hasScope: function (allowedScope) {
            const hasAccess = _verifyAccessibility(
                store.state.auth.scopes,
                allowedScope
            );
            return !!hasAccess;
        },

        verifyPageScope: async function (
            allowedScope,
            redirectUrl,
            verifyMerchant = false
        ) {
            const hasAccess = verifyScope(
                store.state.auth.scopes,
                allowedScope
            );
            if (!hasAccess) {
                if ("$router" in this && "$swal" in this) {
                    await this.$swal.fire({
                        background: this.backgroundColor,
                        title: "No tienes permiso 💢",
                        text: `Permiso requerido ${allowedScope}`,
                        type: "warning",
                        icon: "error",
                        showCancelButton: false,
                        confirmButtonText: "OK",
                        timer: 10000
                    });
                    this.$router.push(redirectUrl);
                } else {
                    alert("No tienes permiso 💢");
                    window.location.href = redirectUrl;
                }
                return false;
            }

            if (verifyMerchant && !this.selectedMerchant.merchantId) {
                await this.$swal.fire({
                    background: this.backgroundColor,
                    type: "info",
                    title: "Debes de seleccionar un comercio primero",
                    text: "Vuelve cuando hayas seleccionado un comercio en la parte superior",
                    timer: 5000
                });
                this.$router.push("/app");
                return false;
            }
            return true;
        }
    };
};

export default abilityPluginImpl;
