import { handleErrorAxios } from "@/common/lib/formatter";
import Http from "@/common/lib/http";

const actions = {
    async createCart(
        { commit, dispatch },
        { expiredAt, merchantId, merchantType, name, references }
    ) {
        try {
            const { data } = await Http.instance.post("/bo/carts", {
                name,
                merchantId,
                merchantType,
                references,
                expiredAt
            });
            return data;
        } catch (errorAxios) {
            const parseError = handleErrorAxios(errorAxios, dispatch);
            commit("SET_ERROR_CARTS", parseError);
            throw parseError;
        }
    },

    async listCartsPaginated({ commit, dispatch }, { complete, page }) {
        try {
            const { data } = await Http.instance.get("/bo/carts", {
                params: { page, complete }
            });

            return data;
        } catch (errorAxios) {
            const parseError = handleErrorAxios(errorAxios, dispatch);
            commit("SET_ERROR_CARTS", parseError);
            throw parseError;
        }
    },

    async getCartById({ commit, dispatch }, { id }) {
        try {
            const { data } = await Http.instance.get(`/bo/carts/${id}`);
            return data;
        } catch (errorAxios) {
            const parseError = handleErrorAxios(errorAxios, dispatch);
            commit("SET_ERROR_CARTS", parseError);
            throw parseError;
        }
    },

    async updateCart(
        { commit, dispatch },
        { expiredAt, id, merchantId, merchantType, name, references }
    ) {
        try {
            const { data } = await Http.instance.patch("/bo/carts", {
                id,
                name,
                merchantId,
                merchantType,
                expiredAt,
                references
            });
            return data;
        } catch (errorAxios) {
            const parseError = handleErrorAxios(errorAxios, dispatch);
            commit("SET_ERROR_CARTS", parseError);
            throw parseError;
        }
    },

    async deleteCart({ commit, dispatch }, { id }) {
        try {
            await Http.instance.delete(`/bo/carts/${id}`);
        } catch (errorAxios) {
            const parseError = handleErrorAxios(errorAxios, dispatch);
            commit("SET_ERROR_CARTS", parseError);
            throw parseError;
        }
    }
};

export default actions;
