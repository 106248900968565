const mutations = {
    SET_ERROR_FILES(state, payload) {
        state.error = payload;
    },
    SET_URL_UPLOAD(state, payload) {
        state.urlUpload = payload;
    }
};

export default mutations;
